import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import TextInput from "../../components/Input/TextInput";
import { MuiPhoneComponent } from "../../components/Input/MuiPhoneComponent";
import { createGuest } from "../../api/Webshop.service";
import { updateGuestDetails } from "../../store/slice/Webshop.slice";
import KioskCompletebill from "./KioskCompleteBill";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function KioskCheckout({ open, handleClose, setOpen }) {
  const theme = useTheme();
  const dispatch=useDispatch()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [completeBillOpen, setCompleteBillOpen] = React.useState(false);
  const { deliveryType } = useSelector((store) => store?.Webshop);
  const [AddGuestLoader,setAddGuestLoader] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      zipcode: "",
      place: "",
      deliveryTime: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      address:
        deliveryType === "delivery"
          ? Yup.string().required("Address is required")
          : Yup.string(),
      zipcode:
        deliveryType === "delivery"
          ? Yup.string().required("Zipcode is required")
          : Yup.string(),
      place:
        deliveryType === "delivery"
          ? Yup.string().required("Place is required")
          : Yup.string(),
      deliveryTime:
        deliveryType === "delivery"
          ? Yup.string().required("Delivery time is required")
          : Yup.string(),
    }),
    onSubmit: (values) => {
       addGuestDetails(values)
       setCompleteBillOpen(true);
         handleClose();
    },
  });

  const addGuestDetails=async(data)=>{
    setAddGuestLoader(true)
    const payload = {
      guestData: Object.entries(data).map(([key, value]) => ({
          idfield: key,
          data: value
      }))
  };
    try {
      const response =await createGuest(payload);
      if(response?.status != 200){
        console.error("Error while creating guest", response);
        return;
      }
      else{
        dispatch(updateGuestDetails(response?.data))
        console.log("Guest created successfully", response);

      }
      setAddGuestLoader(false)
      
    } catch (error) {
      console.error("Error while creating guest", error);
      setAddGuestLoader(false)
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%",
            maxWidth: "none",
            height: "auto",
            minHeight: "500px",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{
              color: "#F37120",
              fontWeight: "bold",
              fontSize: "1.2rem",
              mb: 3,
            }}
          >
            Checkout
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={6}>
                <TextInput
                  name="firstName"
                  label="First Name"
                  value={formik?.values?.firstName}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item md={6}>
                <TextInput
                  name="lastName"
                  label="Last Name"
                  value={formik?.values?.lastName}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item md={6}>
                <TextInput
                  name="email"
                  label="Email"
                  value={formik?.values?.email}
                  onChange={formik.setFieldValue}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item md={6}>
                <MuiPhoneComponent
                  style={{ width: "100%" }}
                  label={"Phone"}
                  name={"phone"}
                  value={formik?.values?.phone}
                  onChange={formik.setFieldValue}

                  //error={Boolean(
                  //  errors[field?.name] && touched[field?.name]
                  //)} // Only show error when touched
                  //helperText={touched[field?.name] && errors[field?.name]}
                />
              </Grid>
              {deliveryType === "delivery" && (
                <>
                  <Grid item md={6}>
                    <TextInput
                      name="address"
                      label="Address"
                      value={formik?.values?.address}
                      onChange={formik?.setFieldValue}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextInput
                      name="zipcode"
                      label="Zipcode"
                      value={formik?.values?.zipcode}
                      onChange={formik.setFieldValue}
                      error={
                        formik.touched.zipcode && Boolean(formik.errors.zipcode)
                      }
                      helperText={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                    />
                  </Grid>
                  <Grid item md={3}>
                    <TextInput
                      name="place"
                      label="Place"
                      value={formik?.values?.place}
                      onChange={formik.setFieldValue}
                      error={
                        formik.touched.place && Boolean(formik.errors.place)
                      }
                      helperText={formik.touched.place && formik.errors.place}
                    />
                  </Grid>
                  <Grid item md={6}>
                    <InputLabel>Requested Delivery Time</InputLabel>
                    <Select
                      name="deliveryTime"
                      value={formik?.values?.deliveryTime}
                      onChange={(e)=>formik.setFieldValue('deliveryTime',e.target.value)}
                      error={
                        formik.touched.deliveryTime &&
                        Boolean(formik.errors.deliveryTime)
                      }
                    >
                      <MenuItem value="" disabled>
                        Select Time
                      </MenuItem>
                      {Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </Grid>
                </>
              )}
              <Grid item md={deliveryType === "delivery" ? 6 : 12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#F37120",
                    color: "#fff",
                    py: 2,
                    mt: 1,
                    width: "100%",
                  }}
                >
                  Pay Now
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </BootstrapDialog>
      <KioskCompletebill
        open={completeBillOpen}
        handleClose={() => setCompleteBillOpen(false)}
        back={() => setOpen(true)}
      />
    </React.Fragment>
  );
}
