import { useState, useRef } from "react";
import {
  Box,
  Typography,
  IconButton,
  useMediaQuery,
  useTheme,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import lang from "../../assets/lang.png";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import LanguageMenuItem from "../../components/SelectComponent/LanguageMenuItem";
import { updateSelectedMenuItem } from "../../store/slice/Webshop.slice";
import { useDispatch, useSelector } from "react-redux";
import { IMG_BASE_URL } from "../../utils/utils";

const MenuComponent = ({ MenuItems,handleMenuClick }) => {
  const dispatch = useDispatch(); // Use the Redux dispatch function to update the selected menu item
  const {webShopMenuPosition,webShopCategoryList,selectedMenuItem,languageData,setLanguageData,defaultLanguage,updateDefaultLanguage}=useSelector((store)=>store?.Webshop)
  const scrollContainerRef = useRef(null); // Reference to the scroll container
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Check if the screen size is small
  const handleNextScroll = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({
        left: 120, // Adjust the scroll amount as needed
        behavior: "smooth",
      });
    }
  };

  return (
    <Box
      sx={{
        backgroundColor: "#fff",
        height: "120px",
        pt: 2,
        display:"flex",
        justifyContent:'space-between',
        alignItems:'center',
        
       
      }}
    >
      {/* Menu Items */}
      <Box
        sx={{
          display: "flex",
          position: "relative",
          width: isSmallScreen ? "100%" : "80%", // Full width on small screens
          overflowX: isSmallScreen ? "auto" : "hidden", // Enable horizontal scroll on small screens only
          paddingBottom: "10px",
        }}
      >
        <Box
          ref={scrollContainerRef}
          sx={{
            display: "flex",
            justifyContent: "flex-start",
            gap: isSmallScreen ? "2rem" : "4rem",
            minWidth: "max-content", // Allow items to overflow
            flexShrink: 0, // Prevent items from shrinking
            scrollBehavior: "smooth", // Smooth scrolling behavior
            overflowX: "hidden", // Hide overflow for larger screens
          }}
        >
          {MenuItems?.map((item, i) => (
            <Box
              key={i}
              sx={{
                cursor: "pointer",
                display: "flex",
                justifyContent: "space-between",
                px:2,
                alignItems: "center",
                borderBottom:
                  selectedMenuItem?.name === item?.name
                    ? "3px solid #F37120"
                    : "transparent",
                marginRight: "16px", // Space between items
              }}
              onClick={() => handleMenuClick(item)}
            >
              <Box
                sx={{
                  margin: "10px 0",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: 80,
                  height: 80,
                  backgroundColor:
                    selectedMenuItem?.name === item?.name ? "#F37120" : "transparent",
                  borderRadius: "16px",
                }}
              >
                <img
                  src={`${IMG_BASE_URL}/upload/WebshopCategory${item?.imageFileName?.replace(/\\/g, "/")}`}
                  alt={item?.name}
                  width="50"
                  height="50"
                />
                <Typography
                  sx={{
                    textAlign: "center",
                    color: selectedMenuItem?.name === item?.name ? "#fff" : "#969BA0",
                    fontSize: "11px",
                  }}
                >
                  {item?.name}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
      {!isSmallScreen && 
      <LanguageMenuItem
      languageData={languageData}
      updateLanguageData={setLanguageData}
      defaultLanguage={defaultLanguage}
      updateDefaultLanguage={updateDefaultLanguage}
    />
      }
    </Box>
  );
};

export default MenuComponent;
