import { Backdrop, Box, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import burger from "../../assets/burger.png";
import fries from "../../assets/fries.png";
import drinks from "../../assets/drinks.png";
import icecream from "../../assets/icecream.png";
import deals from "../../assets/deals.png";
import footerImg from "../../assets/footerLogo.png";
import coffee from "../../assets/coffee.png";
import HorizontalMenuItems from "./HorizontalMenuItem";
import SelectMenuItemComponent from "./SelectMenuItemComponent";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setIsProductLoading, setWebshopLoading, setWebshopProductData, setWebshopProductInfo, updateSelectedMenuItem } from "../../store/slice/Webshop.slice";
import { IMG_BASE_URL } from "../../utils/utils";
import { FetchWebShopProductList, FetchWebshopProductsInfo } from "../../api/Webshop.service";



const Menubar = ({getProductList,getProductsInfo}) => {
  const dispatch=useDispatch()
  const {webShopMenuPosition,webShopCategoryList,selectedMenuItem,webshopLoading,defaultLanguage,webShopProductList}=useSelector((store)=>store?.Webshop)
  const {key}=useSelector((store)=>store?.widgetSettings)
  const position = webShopMenuPosition;
  const [isScrollable, setIsScrollable] = useState(false);
  const [scrollOffset, setScrollOffset] = useState(0);


  // Function to handle scrolling to the next items
  const handleScroll = () => {
    setScrollOffset(scrollOffset + 120); // Scroll by 120px (adjustable)
  };
  if(webshopLoading){
    <Backdrop
    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
    open={webshopLoading}
    onClick={()=>dispatch(setWebshopLoading(false))}
  >
    <CircularProgress color="inherit" />
  </Backdrop> 
  }
  const handleMenuClick = (item)=>{
    getProductList(item)
    getProductsInfo(key,defaultLanguage)
    dispatch(updateSelectedMenuItem(item))

  }

  return (
    <>
      {position == "vertical" ?
       (
        <Box
          sx={{
            width: "120px",
            backgroundColor: "#fff",
            height: "auto",
            pt: 2,
            display: "flex", // Add flexbox
            flexDirection: "column", // Stack children vertically
            justifyContent: "space-between", // Ensure space between header, content, and footer
          }}
        >
          <Box>
            {webShopCategoryList?.map((item, i) => 
            (
              <Box
                key={item?.idCategory}
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  borderLeft:
                    selectedMenuItem?.name === item?.name
                      ? "3px solid #F37120"
                      : "transparent",
                }}
                onClick={() =>{
                  handleMenuClick(item)
                }}
              >
                <Box
                  sx={{
                    margin: "10px 0",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: 80,
                    height: 80,
                    backgroundColor:
                      selectedMenuItem?.name === item?.name
                        ? "#F37120"
                        : "transparent",
                    borderRadius: "16px",
                  }}
                >
                  <img
                    src={`${IMG_BASE_URL}/upload/WebshopCategory${item?.imageFileName?.replace(/\\/g, "/")}`}
                    alt={item?.name}
                    width="50"
                    height="50"
                  />
                  <Typography
                    sx={{
                      textAlign: "center",
                      color:
                        selectedMenuItem?.name === item?.name ? "#fff" : "#969BA0",
                      fontSize: "11px",
                    }}
                  >
                    {item?.name}
                  </Typography>
                </Box>
              </Box>
            ))}
           
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "row", // Always keep it row
              justifyContent: "flex-end",
              alignItems: "flex-end",
              px: { xs: 2, md: 4 },
              py: 2,
            }}
          >
            <Box
              sx={{
                flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
          </Box>
        </Box>
      )
      : position == "horizontal" ? (
        <HorizontalMenuItems MenuItems={webShopCategoryList} handleMenuClick={handleMenuClick} />
      )
      :
        <SelectMenuItemComponent  handleMenu={handleMenuClick} />
      }
      
    </>
  );
};

export default Menubar;
