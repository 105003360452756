// import * as React from "react";
// import Button from "@mui/material/Button";
// import { styled } from "@mui/material/styles";
// import Dialog from "@mui/material/Dialog";
// import DialogTitle from "@mui/material/DialogTitle";
// import DialogContent from "@mui/material/DialogContent";
// import DialogActions from "@mui/material/DialogActions";
// import IconButton from "@mui/material/IconButton";
// import CloseIcon from "@mui/icons-material/Close";
// import Typography from "@mui/material/Typography";
// import {
//   Badge,
//   Box,
//   Grid,
//   Grid2,
//   InputLabel,
//   MenuItem,
//   Select,
//   TextField,
//   useMediaQuery,
// } from "@mui/material";
// import AddIcon from "@mui/icons-material/Add";
// import RemoveIcon from "@mui/icons-material/Remove";
// import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import { useTheme } from "@emotion/react";
// import burgerImg from "../../assets/menuBurgerImg.png";
// import MenuListCardsComponent from "./MenuListCardsComponent";
// import DeleteIcon from "@mui/icons-material/Delete";
// import TextInput from "../../components/Input/TextInput";
// import { useSelector } from "react-redux";
// import Completebill from "./Completebill";

// const BootstrapDialog = styled(Dialog)(({ theme }) => ({
//   "& .MuiDialogContent-root": {
//     padding: theme.spacing(2),
//   },
//   "& .MuiDialogActions-root": {
//     padding: theme.spacing(1),
//   },
// }));
// export default function Checkout({ open, handleClose,setOpen }) {
//   const theme = useTheme();
//   const [completeBillOpen,setCompleteBillOpen]=React.useState(false)
//   const {deliveryType}=useSelector((store)=>store?.Webshop)
//   const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
//   const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
//   const [fname,setFname]=React.useState('')
//   const[lname,setLname]=React.useState('')
//   const[email,setEmail]=React.useState('')
//   const[phone,setPhone]=React.useState('')
//   const[address,setAddress]=React.useState('')

//   return (
//     <React.Fragment>
//       <BootstrapDialog
//         onClose={handleClose}
//         aria-labelledby="customized-dialog-title"
//         open={open}
//         PaperProps={{
//           sx: {
//             width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
//             maxWidth: "none", // Prevents default Material-UI maxWidth constraints
//             height: "auto", // Allows height to adjust dynamically
//             minHeight:'500px'
//           },
//         }}
//       >
//         <Box>
//           <DialogTitle
//             id="recommendation-dialog-title"
//             sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem",mb:3 }}
//           >
//             Checkout
//           </DialogTitle>
//           <IconButton
//             aria-label="close"
//             onClick={handleClose}
//             sx={{
//               position: "absolute",
//               right: 8,
//               top: 8,
//               color: (theme) => theme.palette.grey[500],
//             }}
//           >
//             <CloseIcon />
//           </IconButton>
//         </Box>
//         <DialogContent sx={{mt:2}}>
//         <Grid2 container spacing={2} alignItems={"center"}>
//           <Grid2 size={{md:6}}>
//             <TextInput name='firstName' placeholder="Type here" label='First Name' onChange={()=>{}} />
//           </Grid2>
//           <Grid2 size={{md:6}}>
//           <TextInput name='lastName' placeholder="Type here"  onChange={()=>{}}   label='Last Name' />
//           </Grid2>
//           <Grid2 size={{md:6}}>
//             <TextInput name='email' placeholder="Type here" label='Email' onChange={()=>{}} />
//           </Grid2>
//           <Grid2 size={{md:6}}>
//           <TextInput name='phone' placeholder="Type here"  onChange={()=>{}}   label='Phone' />
//           </Grid2>
//           {deliveryType == 'delivery' && <Grid2 size={{md:6}}>
//           <TextInput name='address' placeholder="Type here"  onChange={()=>{}}   label='Address' />

//           </Grid2>}
//           {deliveryType == 'delivery' && <Grid2 size={{md:3}}>
//           <TextInput name='zipcode' placeholder="Type here"  onChange={()=>{}}   label='Zipcode' />
//           </Grid2>}
//           {deliveryType == 'delivery' && <Grid2 size={{md:3}}>
//           <TextInput name='place' placeholder="Type here"  onChange={()=>{}}   label='Place' />
//           </Grid2>}
//           {deliveryType == 'delivery' && <Grid2 size={{md:6}}>
//           <InputLabel
//         sx={{ mb: 2, fontWeight: 'bold', fontSize: '15px', color: theme?.details?.formLabelColor}}
//         htmlFor={'time'} // Changed to use 'name' for accessibility
//       >
//        Requested Delivery Time
//       </InputLabel>
//           <Select
//    // sx={{ width: "100%"}}
//     defaultValue="" // Optional: set a default value
//     displayEmpty
//     sx={{
//       width: "100%",
//       height: "40px",
//       borderRadius: "8px",
//       backgroundColor: "#FDFDFD",
//       '& .MuiSelect-outlined': {
//         padding: '8px', // Adjust padding for the dropdown content
//       },
//       '& .MuiOutlinedInput-notchedOutline': {
//         borderColor: "#EBEBEB", // Default border color
//       },
//       '&:hover .MuiOutlinedInput-notchedOutline': {
//         borderColor: "#EBEBEB", // Hover border color
//       },
//       '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
//         borderColor: "#EBEBEB", // Focused border color
//       },
//     }}
//   >
//     <MenuItem value="" disabled>
//       Select Time
//     </MenuItem>
//     {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
//       <MenuItem key={number} value={number}>
//         {number}
//       </MenuItem>
//     ))}
//   </Select>
//           </Grid2>}
//           <Grid2 size={{md:deliveryType == 'delivery' ? 6 : 12} } sx={{display:'flex',justifyContent:'center',alignItems:'center'}}>

//          <Button variant="contained" sx={{backgroundColor:'#F37120',color:"#fff",py:2,mt:1,width:deliveryType == 'delivery' ?'100%' : '50%' }}
//          onClick={()=>{
//           setCompleteBillOpen(true)
//           handleClose()
//         }}
//          >Pay Now</Button>
//           </Grid2>
//         </Grid2>
//         </DialogContent>
//         <DialogActions></DialogActions>
//       </BootstrapDialog>
//       <Completebill  open={completeBillOpen} handleClose={()=>setCompleteBillOpen(false)} back={()=>setOpen(true)}/>
//     </React.Fragment>
//   );
// }

import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  InputLabel,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { useDispatch, useSelector } from "react-redux";
import Completebill from "./Completebill";
import TextInput from "../../components/Input/TextInput";
import { MuiPhoneComponent } from "../../components/Input/MuiPhoneComponent";
import { createGuest } from "../../api/Webshop.service";
import { updateGuestDetails } from "../../store/slice/Webshop.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function Checkout({ open, handleClose, setOpen }) {
  const theme = useTheme();
  const dispatch=useDispatch()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [completeBillOpen, setCompleteBillOpen] = React.useState(false);
  const { deliveryType } = useSelector((store) => store?.Webshop);
  const [AddGuestLoader,setAddGuestLoader] = React.useState(false);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      address: "",
      zipcode: "",
      place: "",
      deliveryTime: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("First Name is required"),
      lastName: Yup.string().required("Last Name is required"),
      email: Yup.string()
        .email("Invalid email format")
        .required("Email is required"),
      phone: Yup.string().required("Phone is required"),
      address:
        deliveryType === "delivery"
          ? Yup.string().required("Address is required")
          : Yup.string(),
      zipcode:
        deliveryType === "delivery"
          ? Yup.string().required("Zipcode is required")
          : Yup.string(),
      place:
        deliveryType === "delivery"
          ? Yup.string().required("Place is required")
          : Yup.string(),
      deliveryTime:
        deliveryType === "delivery"
          ? Yup.string().required("Delivery time is required")
          : Yup.string(),
    }),
    onSubmit: (values) => {
       addGuestDetails(values)
       setCompleteBillOpen(true);
         handleClose();
    },
  });

  const addGuestDetails=async(data)=>{
    setAddGuestLoader(true)
    const payload = {
      guestData: Object.entries(data).map(([key, value]) => ({
          idfield: key,
          data: value
      }))
  };
    try {
      const response =await createGuest(payload);
      if(response?.status != 200){
        console.error("Error while creating guest", response);
        return;
      }
      else{
        dispatch(updateGuestDetails(response?.data))
        console.log("Guest created successfully", response);

      }
      setAddGuestLoader(false)
      
    } catch (error) {
      console.error("Error while creating guest", error);
      setAddGuestLoader(false)
    }
  }

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%",
            maxWidth: "none",
            height: "auto",
            minHeight: "500px",
          },
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{
              color: "#F37120",
              fontWeight: "bold",
              fontSize: "1.2rem",
              mb: 3,
            }}
          >
            Checkout
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
          <DialogContent sx={{ mt: 2 }}>
            <Grid container spacing={2} alignItems="center">
              <Grid item md={6} sm={12} xs={12}>
                <TextInput
                  name="firstName"
                  label="First Name"
                  value={formik?.values?.firstName}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextInput
                  name="lastName"
                  label="Last Name"
                  value={formik?.values?.lastName}
                  onChange={formik.setFieldValue}
                  error={
                    formik.touched.lastName && Boolean(formik.errors.lastName)
                  }
                  helperText={formik.touched.lastName && formik.errors.lastName}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <TextInput
                  name="email"
                  label="Email"
                  value={formik?.values?.email}
                  onChange={formik.setFieldValue}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item md={6} sm={12} xs={12}>
                <MuiPhoneComponent
                  style={{ width: "100%" }}
                  label={"Phone"}
                  name={"phone"}
                  value={formik?.values?.phone}
                  onChange={formik.setFieldValue}

                  //error={Boolean(
                  //  errors[field?.name] && touched[field?.name]
                  //)} // Only show error when touched
                  //helperText={touched[field?.name] && errors[field?.name]}
                />
              </Grid>
              {deliveryType === "delivery" && (
                <>
                  <Grid item md={6} sm={12}>
                    <TextInput
                      name="address"
                      label="Address"
                      value={formik?.values?.address}
                      onChange={formik?.setFieldValue}
                      error={
                        formik.touched.address && Boolean(formik.errors.address)
                      }
                      helperText={
                        formik.touched.address && formik.errors.address
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <TextInput
                      name="zipcode"
                      label="Zipcode"
                      value={formik?.values?.zipcode}
                      onChange={formik.setFieldValue}
                      error={
                        formik.touched.zipcode && Boolean(formik.errors.zipcode)
                      }
                      helperText={
                        formik.touched.zipcode && formik.errors.zipcode
                      }
                    />
                  </Grid>
                  <Grid item md={3} sm={12} xs={12}>
                    <TextInput
                      name="place"
                      label="Place"
                      value={formik?.values?.place}
                      onChange={formik.setFieldValue}
                      error={
                        formik.touched.place && Boolean(formik.errors.place)
                      }
                      helperText={formik.touched.place && formik.errors.place}
                    />
                  </Grid>
                  <Grid item  xs={12}>
                    <InputLabel>Requested Delivery Time</InputLabel>
                    <Select
                      name="deliveryTime"
                      value={formik?.values?.deliveryTime}
                      onChange={(e)=>formik.setFieldValue('deliveryTime',e.target.value)}
                      error={
                        formik.touched.deliveryTime &&
                        Boolean(formik.errors.deliveryTime)
                      }
                      fullWidth
                    >
                      <MenuItem value="" disabled>
                        Select Time
                      </MenuItem>
                      {Array.from({ length: 10 }, (_, i) => i + 1).map(
                        (number) => (
                          <MenuItem key={number} value={number}>
                            {number}
                          </MenuItem>
                        )
                      )}
                    </Select>
                  </Grid>
                </>
              )}
              <Grid item  xs={12}>
                <Button
                  type="submit"
                  variant="contained"
                  sx={{
                    backgroundColor: "#F37120",
                    color: "#fff",
                    py: 2,
                    mt: 1,
                    width: "100%",
                  }}
                >
                  Pay Now
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </form>
      </BootstrapDialog>
      <Completebill
        open={completeBillOpen}
        handleClose={() => setCompleteBillOpen(false)}
        back={() => setOpen(true)}
      />
    </React.Fragment>
  );
}
