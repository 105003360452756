import React, { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  CardActions,
  Typography,
  Button,
  Box,
  Badge,
  Divider,
  Grid,
  useMediaQuery,
} from "@mui/material";
import burgerImg from "../../assets/menuBurgerImg.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { IMG_BASE_URL, format_Number } from "../../utils/utils";
import { UpdateAddToCartProducts, setAddToCartOpen, setItemCount, setSelectedProduct, setSupplementInfo, setTotalProductCount, setWebshopProductData } from "../../store/slice/Webshop.slice";
//import { setItemCounts } from "../../store/slice/Webshop.slice";
import dayjs from "dayjs";
import KioskAddToCart from "./KioskAddToCart";
import ExpandableText from "./ExpandableText";




const RibbonTag = ({ data }) => {
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 50,
        right: -30,
        backgroundColor: 'red',
        color: 'white',
        padding: '5px 50px',
        fontWeight: 'bold',
        transform: 'rotate(35deg)',
        transformOrigin: 'top right',
        zIndex: 1,
        fontSize: '12px',
        borderRadius: '4px',
      }}
    >
      {data}
    </Box>
  );
};


const KioskMenuListCardsComponent = ({ data = [],recommededProducts=false }) => {
  const dispatch = useDispatch();
  const { shopType, webShopProductInfo, itemCount,supplementsInfo,webshopSettings,totalProductsCount,addToCartProducts,addToCartOpen} = useSelector(
    (store) => store?.Webshop
  );
  const theme = useTheme();
  let specialPrice;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [selectedSupplements, setSelectedSupplements] = useState(supplementsInfo);
  const [isOpenAlert,setIsAlert]=useState(false)
  const currentDate = dayjs();
  const [itemCounts, setItemCounts] = useState(itemCount);
  const[action,setAction]=useState('')

    // Initialize counts to 1 for all items
    React.useEffect(() => {
      if(!recommededProducts){
        const initialCounts = data.reduce((acc, item) => {
          acc[item?.idproduct] = 1;
          return acc;
        }, {});
        console.log("callling initial count")
        setItemCounts((prev)=>({...prev,...initialCounts}));
      }
    }, [data]);
  const navigate = useNavigate();

  const handleClickOpen = (item) => {
    if(recommededProducts){
      if(!addToCartProducts?.find(product => product.idproduct === item.idproduct))
      dispatch(UpdateAddToCartProducts(item))
      return
    }
    dispatch(setSelectedProduct(item))
    // setOpen(true);
    dispatch(setAddToCartOpen(true))
  };
  const handleClose = () => {
   // setOpen(false);
   dispatch(setAddToCartOpen(false))
  };

  const handleBadgeClick = (productId, supplementItem, detailsKey) => {
    setSelectedSupplements((prev) => {
      // Find the index of the existing product in the array
      const productIndex = prev.findIndex(
        (product) => product.idproduct === productId
      );
  
      if (productIndex > -1) {
        // Product exists, toggle the supplement
        const existingProduct = prev[productIndex];
        const existingSupplements = existingProduct.supplements || [];
  
        // Check if the supplement is already added
        const isAlreadyAdded = existingSupplements.some(
          (item) =>
            item.idwebshopsupplementitem ===
            supplementItem.idwebshopsupplementitem &&
            item.detailsKey === detailsKey // Match both supplement ID and detailsKey
        );
  
        if (isAlreadyAdded) {
          // Remove the supplement if it already exists
          const updatedSupplements = existingSupplements.filter(
            (item) =>
              item.idwebshopsupplementitem !==
                supplementItem.idwebshopsupplementitem ||
              item.detailsKey !== detailsKey
          );
  
          // If no supplements remain, remove the product from the array
          if (updatedSupplements.length === 0) {
            return prev.filter((_, index) => index !== productIndex);
          }
  
          // Otherwise, update the product with the new supplements
          return prev.map((product, index) =>
            index === productIndex
              ? { ...product, supplements: updatedSupplements }
              : product
          );
        } else {
          // Add the new supplement to the product
          return prev.map((product, index) =>
            index === productIndex
              ? {
                  ...product,
                  supplements: [
                    ...existingSupplements,
                    { ...supplementItem, detailsKey },
                  ],
                }
              : product
          );
        }
      } else {
        // Product does not exist, add it as a new entry
        return [
          ...prev,
          {
            idproduct: productId,
            supplements: [{ ...supplementItem, detailsKey }],
          },
        ];
      }
    });
  };
  useEffect(() => {
    dispatch(setItemCount(itemCounts));
  
    // Track already processed product IDs to avoid double-counting
    const countedProductIds = new Set();
  
    const totalCountProducts = addToCartProducts
      .filter((prod) => {
        // Check if idProduct exists, is valid, and hasn't been processed yet
        if (prod.idproduct && Object.keys(itemCounts).includes(prod.idproduct.toString()) && !countedProductIds.has(prod.idproduct)) {
          countedProductIds.add(prod.idproduct); // Mark this product as processed
          return true;
        }
        return false;
      })
      .reduce((sum, prod) => sum + (itemCounts[prod.idproduct] || 0), 0); // Sum up the counts from itemCounts
  
    console.log("Total counts:", totalCountProducts);
  
    dispatch(setTotalProductCount(totalCountProducts));
  }, [itemCounts, addToCartProducts]);
  

  useEffect(() => {
    if(selectedSupplements.length > 0)
    dispatch(setSupplementInfo([...selectedSupplements]));
  }, [selectedSupplements]);
 

  if (!Array.isArray(data)) {
    console.error("Invalid data prop: expected an array");
    return null; // Render nothing if data is invalid
  }

  const ProductPrice = (id, productPrice) => {
    // Find supplements info for the product
    const supplementInfo = supplementsInfo?.find(
      (item) => item?.idproduct == id
    );

    // Calculate the total price of supplements for this product
    const supplementTotal =
      supplementInfo?.supplements?.reduce(
        (suppAcc, supplement) => suppAcc + (supplement?.price || 0),
        0
      ) || 0;
    // Calculate the total price for the product including supplements
    const productTotal = productPrice * (itemCount[id] || 0) + supplementTotal;

    return format_Number(productTotal)
  };




  

  return (
    <>
      <Box mt={3} px={2}>
        <Grid container spacing={2} >
          {data?.map((item) => {
            // Check if there is a valid special price for the current date
             specialPrice = item?.specialPriceFields?.find((price) =>
              currentDate.isBetween(
                dayjs(price.specialpricefrom, "DD-MM-YYYY"),
                dayjs(price.specialpriceto, "DD-MM-YYYY"),
                null,
                "[]"
              )
            );

            return (
              <Grid
                key={item?.idproduct}
                item
                xs={12}
                sm={12}
                md={6}
                lg={6 }
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Card
                  sx={{
                    width: isSmallScreen ? "100%" : "80%",
                    minWidth: "180px",
                    maxWidth: "400px",
                    borderRadius: 3,
                    opacity:itemCount[item?.idproduct] > item?.availablestock ? "0.7" : "1",
                    backgroundColor: addToCartProducts?.find((prod)=>prod?.idproduct == item?.idproduct) ? "#FFF7D1" : "#fff",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    height: "100%",
                    boxShadow: 3,
                    position: "relative",
                  }}
                >
                  {itemCount[item?.idproduct] > item?.availablestock && <RibbonTag  data={'sold out'}/>}
   
                  <Box
                    sx={{
                      width: "100%",
                      height: "auto",
                      padding: 2,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      mt: 1,
                      maxHeight: "300px",
                    }}
                  >

                    <img
                      src={`${IMG_BASE_URL}/upload/WebshopProduct${item?.imagefilename?.replace(
                        /\\/g,
                        "/"
                      )}`}
                      alt={item?.name}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        borderRadius: 7,
                      }}
                    />
                  </Box>
                  <CardContent sx={{ flexGrow: 1 }}>
                    <Box display="flex" justifyContent="space-between" mb={1}>
                      <Typography variant="subtitle1" fontWeight="bold">
                        {item?.displayname || item?.displayName}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "1rem",
                          alignItems: "center",
                        }}
                      >
                        {specialPrice ? (
                          <Typography
                            variant="body1"
                            sx={{
                              textDecoration: "line-through",
                              color: "gray",
                            }}
                          >
                            &euro;{ProductPrice(item?.idproduct,item?.price)}
                          </Typography>
                        ) : null}
                        <Typography variant="h6" sx={{ color: "#F37120" }}>
                          &euro;
                          {specialPrice
                            ?  ProductPrice(item?.idproduct,specialPrice?.specialprice)
                            : ProductPrice(item?.idproduct,item?.price)}
                        </Typography>
                      </Box>
                    </Box>
                    <ExpandableText text={item?.info} />
                 
                    {webshopSettings?.showThreeSupplements ||  recommededProducts && <Box
                      display="flex"
                      flexDirection="column"
                      gap={2}
                      flexWrap="wrap"
                      sx={{ minHeight: "60px" }}
                    >
                      {[
                        "supplementProductsDetails1",
                        "supplementProductsDetails2",
                        "supplementProductsDetails3",
                      ].map((detailsKey, index) => (
                        <Box key={index}>
                          {webShopProductInfo
                            ?.find(
                              (product) =>
                                product?.idproduct === item?.idproduct
                            )
                            ?.[detailsKey]?.map((type, idx) => (
                              <Badge
                                key={idx}
                                variant="outlined"
                                sx={{
                                  padding: "4px 8px",
                                  border: "1px solid",
                                  borderColor:
                                    detailsKey === "supplementProductsDetails1"
                                      ? "#00DC1B"
                                      : detailsKey ===
                                        "supplementProductsDetails2"
                                      ? "#F3D120"
                                      : "#9600DC",
                                  color:
                                    detailsKey === "supplementProductsDetails1"
                                      ? "#00DC1B"
                                      : detailsKey ===
                                        "supplementProductsDetails2"
                                      ? "#F3D120"
                                      : "#9600DC",
                                  borderRadius: "8px",
                                  backgroundColor: supplementsInfo?.find(
                                    (supplement) => supplement?.idproduct === item?.idproduct
                                  )?.supplements?.some(
                                    (supp) => supp?.idwebshopsupplementitem === type?.idwebshopsupplementitem
                                  )
                                    ? "#F2F9FF"
                                    : "",
                                  mr: 1,
                                  cursor: "pointer",
                                  
                                  fontSize:"12px"
                                }}
                                onClick={() => handleBadgeClick(item.idproduct, type,detailsKey)}

                              >
                                {type?.displayName} <small style={{marginLeft:"0.4rem",textAlign:'center',marginTop:"0.2rem"}}>&euro;{type?.price}</small>
                              </Badge>
                            ))}
                        </Box>
                      ))}
                    </Box>}
                  </CardContent>
                  <Divider />
                  <CardActions>
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      sx={{ backgroundColor: "#F37120" }}
                      onClick={()=>handleClickOpen(item)}
                      disabled={itemCount[item?.idproduct] > item?.availablestock}
                    >
                      Add to Cart
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </Box>
      <KioskAddToCart
        open={addToCartOpen}
        handleClickOpen={handleClickOpen}
        handleClose={handleClose}
        handleBadgeClick={handleBadgeClick}
        itemCounts={itemCounts}
        setItemCounts={setItemCounts}
       
      />
    </>
  );
};


export default KioskMenuListCardsComponent;
