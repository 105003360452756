import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Badge, Box, Grid, Grid2, useMediaQuery } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import { useDispatch, useSelector } from "react-redux";
import { IMG_BASE_URL, format_Number } from "../../utils/utils";

import { UpdateAddToCartProducts, removeAddToCartProducts, setItemCount, setOpenCart, setSupplementInfo, setTotalProductCount } from "../../store/slice/Webshop.slice";
import dayjs from "dayjs";
import KioskCartDetail from "./KioskCartDetail";
import KioskRecommendationProduct from "./KioskRecommendationProduct";
import ExpandableText from "./ExpandableText";


export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));


export default function  KioskAddToCart  ({
  open,
  handleClose,
  handleBadgeClick,
  itemCounts,
  setItemCounts,

}){
  const {
    shopType,
    webShopProductInfo,
    itemCount,
    supplementsInfo,
    selectedProduct,
    openCart,
    addToCartProducts
  } = useSelector((store) => store?.Webshop);
  const currentDate = dayjs();
 const specialPrice= selectedProduct?.specialPriceFields?.find((price) =>
              currentDate.isBetween(
                dayjs(price.specialpricefrom, "DD-MM-YYYY"),
                dayjs(price.specialpriceto, "DD-MM-YYYY"),
                null,
                "[]")
              )
  const dispatch=useDispatch()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const recommendedProductIds=webShopProductInfo?.find((item)=>item?.idproduct == selectedProduct?.idproduct)?.relatedProducts;
  const recommendedProducts = recommendedProductIds?.map((id) =>
    webShopProductInfo?.find((item) => item?.idproduct === id)
  )?.filter((prod)=> prod)
  console.log("recommedned productsss",recommendedProducts)
  const [recommendationOpen, setRecommendationOpen] = React.useState(false);
  const [nextProduct, setNextProduct] = React.useState(false); // To track showing the next product.
  const handleAddToCart = (data) => {
    // Check if the product already exists in the cart
    const existingProduct = addToCartProducts?.find((prod) => prod.idproduct === data.idproduct);
    if (existingProduct) {
      console.log("Product already exists in the cart. No update needed.");
    } else {
      // If the product does not exist, dispatch the action to add it
      dispatch(UpdateAddToCartProducts(data));
    }
  
    // Open the appropriate modal or dialog
    !recommendedProducts || recommendedProducts?.length == 0 ? dispatch(setOpenCart(true)) : setRecommendationOpen(true); // Open the recommendation dialog
    !recommendedProducts || recommendedProducts?.length == 0 &&  handleClose();
    setNextProduct(true);
  };

  const handleRecommendationClose = () => {
    setRecommendationOpen(false);
    setNextProduct(false); // Reset for next use.
  };

  const handleYes = () => {
    setNextProduct(true); // Show next product recommendation.
  };

  const handleNo = () => {
    setRecommendationOpen(false); // Close recommendation dialog.
    dispatch(setOpenCart(true)); // Open the cart dialog.
    handleClose();
  };

  const closeCartDetail=()=>{
    dispatch(setOpenCart(false))
    handleClose()
  }

  const ProductPrice = (id, productPrice) => {
    // Find supplements info for the product
    const supplementInfo = supplementsInfo?.find(
      (item) => item?.idproduct == id
    );

    // Calculate the total price of supplements for this product
    const supplementTotal =
      supplementInfo?.supplements?.reduce(
        (suppAcc, supplement) => suppAcc + (supplement?.price || 0),
        0
      ) || 0;
    // Calculate the total price for the product including supplements
    const productTotal = productPrice * (itemCount[id] || 0) + supplementTotal;

    return format_Number(productTotal)
  };

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen  ? "100%" : '70%', // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height:'auto', // Allows height to adjust dynamically
            minHeight:"250px"
          },
        }}
      >
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
              zIndex:2
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent >
          <Grid2 container spacing={2} >
            <Grid2 size={{ md: 6, sm: 12,xs:12}}  sx={{display:"flex",justifyContent:'center'}}>
              <Box >
                <img
                  style={{ width: "100%"}}
                  src={`${IMG_BASE_URL}/upload/WebshopProduct${selectedProduct?.imagefilename?.replace(
                    /\\/g,
                    "/"
                  )}`}
                  alt="menu_item_img"
                />
              </Box>
            </Grid2>
            <Grid2 size={{ md: 6, sm: 12,xs:12 }} sx={{position:"relative",minHeight:250}}>
              <Box sx={{height:"100%"}}>
              <Box sx={{display:"flex",justifyContent:'space-between',alignItems:"center",pr:4}}>
                <Box>
                <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                  {selectedProduct?.displayname || selectedProduct?.displayName}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ fontSize: "16px", color: "#969BA0", mt: 2 }}
                >
                  {selectedProduct?.info}
                </Typography>
                </Box>
                <Box>
                {specialPrice ? (
                  <Typography
                    variant="body1"
                    sx={{
                      textDecoration: "line-through",
                      color: "gray",
                      textAlign:"right",
                    }}
                  >
                    &euro; {ProductPrice(selectedProduct?.idproduct,selectedProduct.price)}
                  </Typography>
                ) : null}
                <Typography
                  variant="h6"
                  sx={{
                    fontSize:isSmallScreen ? "20px" : isMediumScreen ? "30px" : "45px",
                    fontWeight: "bold",
                    color: "#F37120",
                    textAlign: "right",
                    mb:1
                  }}
                >
                  &euro;
                  {specialPrice
                    ?ProductPrice(selectedProduct?.idproduct,specialPrice.specialprice)
                    :ProductPrice(selectedProduct?.idproduct,selectedProduct.price,) }
                </Typography>
                
                </Box>
          
              </Box>
              {["supplementProductsDetails1", "supplementProductsDetails2", "supplementProductsDetails3"].some(
  (detailsKey) =>
    webShopProductInfo
      ?.find((product) => product?.idproduct === selectedProduct?.idproduct)
      ?.[detailsKey]?.length > 0
) && (
  <Grid2 container sx={{ mt: 2,mb:2}}>
    <Grid2 size={{ md: 12 }}>
      <Box
        display="flex"
        flexDirection="column"
        gap={2}
        flexWrap="wrap"
        sx={{ minHeight: "60px", mb: 1 }}
      >
        {["supplementProductsDetails1", "supplementProductsDetails2", "supplementProductsDetails3"].map(
          (detailsKey, index) => (
            <Box key={index}>
              {webShopProductInfo
                ?.find(
                  (product) =>
                    product?.idproduct === selectedProduct?.idproduct
                )
                ?.[detailsKey]?.map((type, idx) => (
                  type?.price > 0 &&
                  <Badge
                    key={idx}
                    variant="outlined"
                    sx={{
                      padding: "4px 8px",
                      border: "1px solid",
                      borderColor:
                        detailsKey === "supplementProductsDetails1"
                          ? "#00DC1B"
                          : detailsKey === "supplementProductsDetails2"
                          ? "#F3D120"
                          : "#9600DC",
                      color:
                        detailsKey === "supplementProductsDetails1"
                          ? "#00DC1B"
                          : detailsKey === "supplementProductsDetails2"
                          ? "#F3D120"
                          : "#9600DC",
                      borderRadius: "8px",
                      backgroundColor: supplementsInfo
                        ?.find(
                          (supplement) =>
                            supplement?.idproduct ===
                            selectedProduct?.idproduct
                        )
                        ?.supplements?.some(
                          (supp) =>
                            supp?.idwebshopsupplementitem ===
                            type?.idwebshopsupplementitem
                        )
                        ? "#F2F9FF"
                        : "",
                      mr: 1,
                      cursor: "pointer",
                      mb: 1,
                    }}
                    onClick={() =>
                      handleBadgeClick(
                        selectedProduct.idproduct,
                        type,
                        detailsKey
                      )
                    }
                  >
                    {type?.displayName}{" "}
                    <small
                      style={{
                        marginLeft: "0.4rem",
                        textAlign: "center",
                        marginTop: "0.2rem",
                      }}
                    >
                      &euro;{type?.price}
                    </small>
                  </Badge>
                ))}
            </Box>
          )
        )}
      </Box>
    </Grid2>
  </Grid2>
)}

              <Box sx={{position:"absolute",bottom:0,width:"100%"}}>
                <Grid2 container alignItems={'center'} >
                <Grid2 size={{ md: 12, sm: 12, xs:12 }}>
                <Box sx={{width:"100%"}}>
                <Button            
                  fullWidth
                  onClick={()=>handleAddToCart(selectedProduct)}
                  size={isSmallScreen ? "small" : "large"}
                  sx={{
                    backgroundColor: "#F37120",
                    color: "#fff",
                    width: "100%",
                  }}
                  variant="contained"
                  endIcon={<ShoppingCartIcon sx={{ color: "#fff" }} />}
                >
                  Add To Cart
                </Button>
                </Box>
                </Grid2>
                </Grid2>
              </Box>
              </Box>
            </Grid2>
          </Grid2>
        </DialogContent>
      </BootstrapDialog>

      {/* Recommendation Dialog */}
      <KioskRecommendationProduct
      recommendationOpen={recommendationOpen}
      handleRecommendationClose={handleRecommendationClose}
      nextProduct={nextProduct}
      handleNo={handleNo}
      setNextProduct={setNextProduct}
      handleYes={handleYes}
      recommendedProducts={recommendedProducts}
       />   
      <KioskCartDetail open={openCart} handleClose={closeCartDetail} />
    </React.Fragment>
    
  );
}

