
import React from 'react'
import { Popover, Box, Typography, Button, Grid, Skeleton } from '@mui/material';

const ProductSubscription = ({ Ref, isOpen, handleClose, isLoading }) => {
  return (
    <Popover
    open={isOpen}
    anchorEl={Ref}
    onClose={handleClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left',
    }}
  >
   <Box
       sx={{
        backgroundColor: '#fff',
        width: { xs: '100%', sm: '100%', md: '440px' }, // Full width for xs and sm, fixed width for md and above
        p: 3,
        display: 'flex',
        // flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: '1rem',
      }}
   >
    <Box sx={{display:"flex",justifyContent:"flex-start",alignItems:'center'}}>
    <Typography sx={{fontWeight:"600",color:"#050505",fontSize:"20px",textAlign:"left"}}>Current Plan</Typography>
    </Box>
  
   </Box>
  </Popover>
  )
}

export default ProductSubscription
