import { Badge, Box, Button, Grid2, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import burger from "../../assets/menuBurger.png";
import iceCream from "../../assets/menuIcecream.png";
import cafe from "../../assets/menuCafe.png";
import nuggets from "../../assets/menuNuggets.png";
import deals from "../../assets/menuDeals.png";
import fries from "../../assets/menufries.png";
import softdrink from "../../assets/menuSoftDrinks.png";
import wraps from "../../assets/menuWraps.png"
import footerImg from "../../assets/footerLogo.png";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setIsProductLoading, setOpenCart, setWebshopProductData, setWebshopProductInfo, updateSelectedMenuItem } from '../../store/slice/Webshop.slice';
import { IMG_BASE_URL } from '../../utils/utils';
import { FetchWebShopProductList, FetchWebshopProductsInfo } from '../../api/Webshop.service';

const SelectMenuItemComponent = ({handleMenu}) => {
  const dispatch=useDispatch()
  const {shopType,webShopCategoryList,selectedMenuItem,addToCartProducts,totalProductsCount,defaultLanguage,webShopProductList}=useSelector((store)=>store?.Webshop)
  const {key}=useSelector((store)=>store?.widgetSettings)
  const navigate=useNavigate()

  const handleMenuClick=(item)=>{
    handleMenu(item)
    dispatch(updateSelectedMenuItem(item))
    navigate(`/kioskwebshopMenu`)
  }
  return (
    <Box
    sx={{
      backgroundColor: "lightgrey",
      minHeight: "100vh", // Full viewport height
      display: "flex",
      justifyContent: "center",
      flexDirection:'column',
      alignItems: "center",
      px: 2, // Padding for small screens
      py:2
    }}
  >
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: shopType === "kiosk"
        ? "repeat(2, minmax(0, 400px))"  // Two cards per row with a max size of 400px
        : "repeat(auto-fit, minmax(350px, 400px))", // Responsive grid with max size of 400px
        gap: '2.5rem', // Reduced gap between items
        justifyContent: "center",
        alignContent: "center", // Center vertically and horizontally
       
        width: "100%", // Make grid responsive to container
        mt:5,
        
      }}
    >
      {webShopCategoryList?.map((item,i) => (
        <Box
          key={i}
          sx={{
            backgroundColor:selectedMenuItem?.name == item?.name  ? "#F37120" :"#fff",
            borderRadius: "18px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            padding: "10px",
            boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
            cursor: "pointer",
            transition: "transform 0.3s ease",
            color: "#000000",
            "&:hover": {
                transform: "scale(1.05)",
                boxShadow: "0 8px 16px rgba(0,0,0,0.2)",    
              },
            
          }}
          onClick={() => {
            // dispatch(updateSelectedMenuItem(item))
            // navigate(`/webshopMenu`)
            handleMenuClick(item)
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "0",
              paddingTop: "100%", // Aspect ratio 1:1
              position: "relative",
              borderRadius: "16px",
              overflow: "hidden",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <img
              src={`${IMG_BASE_URL}/upload/WebshopCategory${item?.imageFileName?.replace(/\\/g, "/")}`}
              alt={item?.name}
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                objectFit: "contain", // Maintain aspect ratio
              }}
            />
          </Box>
          <Typography
            sx={{
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "700",
              mt: 1,
              color:selectedMenuItem?.name == item?.name  ? "#fff" : "#00000"

            }}
          >
            {item?.name}
          </Typography>
          <Typography
            sx={{
              color: selectedMenuItem?.name == item?.name ? "#fff" : "#969BA0",
              fontSize: "12px",
              lineHeight: "1.4rem",
              textAlign: "center",
              mt: 1,
             
            
            }}
          >
            {item?.info}
          </Typography>
        </Box>
      ))}
    </Box>
    <Grid2 container mt={4} sx={{width:"100%"}}> 
       <Grid2 size={{md:2}}>
        <Box
              sx={{
             //   flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
        </Grid2>
        <Grid2 size={{md:7}}>
            <Typography sx={{color:"#969BA0"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum eget nisi placerat viverra. Donec non ipsum vel nisi fermentum consectetur. Nulla facilisi. Donec id velit vel eros dictum tempor.
            </Typography>
        </Grid2>
        {shopType != 'kiosk' && <Grid2  size={{md:3}} sx={{display:"flex",justifyContent:"flex-end"}}>
        <Button onClick={()=>{
              if(addToCartProducts?.length > 0){
                dispatch(setOpenCart(true))
              }
            }} size='large' sx={{backgroundColor:"#F37120",color:"#fff"}} variant="contained" endIcon={
              <Badge
              badgeContent={totalProductsCount}
              color="primary"
              sx={{
                ".MuiBadge-dot": {
                  backgroundColor: "black ",  // Set background color to black for the badge
                },
                ".MuiBadge-dotColorPrimary": {
                  backgroundColor: "black",  // Set the background color to black for primary badge
                },
                ".MuiBadge-dot .MuiBadge-dot": {
                  color: "white",            // Set the text color inside the badge to white
                }
              }}
            >
              <ShoppingCartIcon sx={{ color: "#fff" }} />
            </Badge>
           }
            >
  View Cart
</Button>
        </Grid2>}


    </Grid2>
  </Box>
  
  );
};

export default SelectMenuItemComponent;

