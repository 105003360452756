const BASE_URL = process.env.REACT_APP_API_URL;
const SECRET_KEY = process.env.REACT_APP_SECRET_KEY

const apiEndpoints = {
  GET_LANGUAGE: (key)=>`${BASE_URL}api/Widget/GetLanguages?key=${key}`,
  GET_WIDGET_SETTING: (language,key) =>
        `${BASE_URL}api/Widget/GetSettings?key=${key}&lang=${language}`,
    GET_WIDGET_TITLE_AND_INFO: (setupID, language) =>
        `${BASE_URL}api/Widget/GetWidgetTitleandInfo?idwidgetsetup=${setupID}&lang=${language}`,
  GET_WIDGET_POSITION: (setupID) =>
    `${BASE_URL}api/Widget/GetWidgetPosition?idwidgetsetup=${setupID}`,
  GET_WIDGET_SETUP: (setupID) =>
    `${BASE_URL}api/Widget/GetWidgetSetup?idwidgetsetup=${setupID}`,
  GET_WIDGETS_CONFIG:(key,id,lang)=>`${BASE_URL}api/Widget/GetWidgetSettings?key=${key}&idwidget=${id}&lang=${lang ?lang : 'EN'}`,
  GET_CALENDER_DETAILS: (
    key,
    noOfGuests,
    widgetId,
    reservationRequestMin = 0,
    reservationRequestMax = 0
  ) =>
    `${BASE_URL}api/Widget/GetWidgetChecksOnFieldChange?key=${key}&numberOfGuests=${noOfGuests}&idwidgetsetup=${widgetId}&reservationRequestMin=${reservationRequestMin}&reservationRequestMax=${reservationRequestMax}`,
  GET_RESERVATION_TYPE: `${BASE_URL}api/Widget/GetReservationTypes`,
  GET_PRODUCTS_RESERVATION_TUPE: (key, idproducthoreca) =>
    `${BASE_URL}api/Widget/GetProductForReservationType?key=${key}&idproducthoreca=${idproducthoreca}`,
  GET_FREE_TABLES: `${BASE_URL}api/Widget/GetFreeTables`,
  GET_RESERVATION_GROUP_TYPE: `${BASE_URL}api/Widget/GetReservationGroupTypes`,
  GET_GUEST_DETAILS: (key, ID,lang) =>
       // `${BASE_URL}api/Widget/GetFields?id=${ID}&key=${key}&lang=${lang}`,
      `${BASE_URL}api/Widget/GetGuestDetails?idreservationtype=${ID}&key=${key}&lang=${lang}`,
  GET_EXTRA_PRODUCTS_DETAILS: (ID,lang) =>
    `${BASE_URL}api/Widget/GetUpsaleProductsForReservationType?idreservationtype=${ID}&lang=${lang}`,
  CREATE_RESERVATION: `${BASE_URL}api/Widget/CreateReservation `,
  TRANSACTIONCOST_RESTYPE: (key, ID,lang) =>
    `${BASE_URL}api/Widget/GetTransactionCostForReservationType?key=${key}&idreservationtype=${ID}&lang=${lang}`,
  COMBINATION_WIDGET: (
    idreservationtype,
    key,
    arrivaldate,
    arrivalTime,
    departureTime,
    totalPeople,
    lang
  ) =>
    `${BASE_URL}api/Widget/GetCombinationsForWidget?idreservationtype=${idreservationtype}&key=${key}&arrivaldate=${arrivaldate}&arrivalTime=${arrivalTime}&departureTime=${departureTime}&totalPeople=${totalPeople}&language=${lang}`,
  GET_FEEDBACK:(key)=>`${BASE_URL}api/Widget/GetEnquete?secretkey=${key}`,
  CREATE_FEEDBACK:`${BASE_URL}api/Widget/CreateEnquete`,
  CANCEL_RESERVATION:`${BASE_URL}api/Widget/CancelReservation`,
  GET_RESERVATION_DETAILS:(key,secretKey)=>`${BASE_URL}api/Widget/GetReservationDetails?key=${key}&secretkey=${secretKey}`,
  GET_OPENING_HOURS:(key,lang)=>`${BASE_URL}api/Widget/GetHorecaOpeningsHours?key=${key}&lang=${lang}`,
  GET_EXTRA_INFO_FIELDS:(resID,lang,key)=>`${BASE_URL}api/Widget/GetFields?id=${resID}&lang=${lang}&key=${key}`,
  GET_RESERVATION_PDF:(key,secretkey)=>`${BASE_URL}api/Widget/GetReservationPdf?key=${key}&secretkey=${secretkey}`,
  GET_WEBSHOP_WIDGET_SETTINGS:(KEY)=>`${BASE_URL}api/Widget/GetWebshopWidgetSetup?key=${KEY}`,
  GET_WEBSHOP_CATEGORIES:(KEY,LANG)=>`${BASE_URL}api/Widget/GetWebshopCategories?key=${KEY}&lang=${LANG}`,
  GET_WEBSHOP_PRODUCTSLIST:(KEY,LANG,CategoryId)=>`${BASE_URL}api/Widget/GetWebshopProductList?key=${KEY}&lang=${LANG}&idcategory=${CategoryId}`,
  GET_WEBSHOP_PRODUCT_DETAILS:(KEY,LANG,PRODUCTID)=>`${BASE_URL}api/Widget/GetProductDetails?key=${KEY}&lang=${LANG}&idproduct=${PRODUCTID}`,
  CREATE_WEBSHOP_ORDER:`${BASE_URL}api/Widget/CreateOrder`,
  CREATE_GUEST:`${BASE_URL}api/Widget/CreateGuest`

};
export default apiEndpoints;
