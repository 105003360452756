import { Backdrop, Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DeliveryTypeScreen from './DeliveryTypeScreen';
import footerImg from '../../assets/footerLogo.png';
import Menubar from './Menubar';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { updateShopType } from '../../store/slice/widgetSetting.slice';
import { getLanguageDetails } from '../../api/HorecaWidget.service';
import { setLanguageData, setWebshopCategoryData, setWebshopLoading, setWebshopSettings, updateDefaultLanguage, updateSelectedMenuItem } from '../../store/slice/Webshop.slice';
import { FetchWebShopCategories, FetchWebshopSettings } from '../../api/Webshop.service';

const WebshopMain = () => {
  const[langLoading,setLangLoading] = useState(false)
  const location=useLocation();
  const dispatch=useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const {key}=useSelector((store)=>store?.widgetSettings)
  const {defaultLanguage,webshopLoading,shopType}=useSelector((store)=>store?.Webshop);

  const fetchLanguageDetails = async () => {
    dispatch(setWebshopLoading(true))
    try {
        const response = await getLanguageDetails(key);
        if(response?.status != 200){
            console.error("Error fetching language details widget:", response);
            return;
        }
        dispatch(setLanguageData(response?.data));
        const langParam = queryParams.get("lang");
        if (response?.data) {
            const defaultLang = !defaultLanguage && !langParam
                ? response?.data?.find((item) => item?.defaultLanguage)?.langcode
                : langParam ? langParam :  defaultLanguage;
              
             dispatch(updateDefaultLanguage(defaultLang));
        }
        setLangLoading(false);
    } catch (error) {
        console.error("Error fetching language details widget:", error?.message);
        setLangLoading(false);
        dispatch(setWebshopLoading(true))
    }
};

const fetchWebshopSettings=async()=>{
  try {
    const response= await FetchWebshopSettings(key)
    if(response?.status != 200){
      console.error("Error fetching settings widget:", response);
      return;
    }
    else{
      dispatch(setWebshopSettings(response?.data))
    }
    dispatch(setWebshopLoading(false))
    
  } catch (error) {
    console.error("Error fetching settings widget:", error?.message); 
    dispatch(setWebshopLoading(false))
  }
}

const getCategories =async()=>{
  try {
    const response=await FetchWebShopCategories(key,defaultLanguage)
    if(response?.status!= 200){
        console.error("Error fetching categories widget:", response);
        return;
    }
    else{
      dispatch(setWebshopCategoryData(response?.data));
      dispatch(updateSelectedMenuItem(response?.data[0]))
    }
    dispatch(setWebshopLoading(false))
    
  } catch (error) {
    console.error("Error fetching categories widget:", error?.message);  
    dispatch(setWebshopLoading(false))
  }
}
useEffect(()=>{
  fetchLanguageDetails();
},[])

useEffect(()=>{
  if(defaultLanguage)
  getCategories();
},[defaultLanguage])

useEffect(()=>{
  if(key)  // Check if key is provided in URL parameters
  fetchWebshopSettings();
},[key])



if(webshopLoading)
return(
  <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={webshopLoading}
  onClick={()=>dispatch(setWebshopLoading(false))}
>
  <CircularProgress color="inherit" />
</Backdrop> 
)

//   if(shopType == 'kiosk'){
//     return (
//       <Box
//       sx={{
//         width: '100%',
//         backgroundColor: '#EBEBEB',
//         minHeight: '100vh',
//         display: 'flex',
//         flexDirection: 'column', 
//       }}
//     >
//         {/*menu item */}
       
//       {/* Main Content */}
//       <Box sx={{ flex: 1, pt: 5 }}> 
//         <DeliveryTypeScreen />
//       </Box>

//       {/* Footer Section */}
// <Box
//   sx={{
//     display: 'flex',
//     flexDirection: 'row', // Always keep it row
//     flexWrap: 'wrap', // Allow wrapping for responsiveness
//     gap: '1rem', // Reduce gap for small screens
//     alignItems: 'center',
//     justifyContent:{md:"flex-start",sm:"center"} , // Center align on smaller screens
//     px: { xs: 2, md: 4 },
//     py: 2,
//   }}
// >
//   <Box
//     sx={{
//       flex: '0 1 auto', 
//       display: 'flex',
//       alignItems: 'center',
//       justifyContent: 'center', 
//     }}
//   >
//     <img
//       src={footerImg}
//       alt="footerLogo"
//       style={{ minWidth: '76px', height: 'auto' }}
//     />
//   </Box>

 
//   <Typography
//     sx={{
//       flex: '1 1 auto', // Allow the text to grow/shrink
//       color: '#969BA0',
//       fontSize: '12px',
//       textAlign: 'justify',
//       maxWidth: { xs: '100%', sm: '70%' }, // Adjust width for small screens
//       wordBreak: 'break-word', // Handle long text gracefully
//     }}
//   >
//     Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
//     consectetur eget erat nec. Molestie mattis adipiscing sit condimentum.
//     Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam
//     mauris et aliquet sit commodo sagittis.
//   </Typography>
// </Box>

//     </Box>
//     )
//   }
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#EBEBEB',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column', 
      }}
    >
        {/*menu item */}
       
      {/* Main Content */}
      <Box sx={{ flex: 1, pt: 5 }}> 
        <DeliveryTypeScreen />
      </Box>

      {/* Footer Section */}
<Box
  sx={{
    display: 'flex',
    flexDirection: 'row', // Always keep it row
    flexWrap: 'wrap', // Allow wrapping for responsiveness
    gap: '1rem', // Reduce gap for small screens
    alignItems: 'center',
    justifyContent:{md:"flex-start",sm:"center"} , // Center align on smaller screens
    px: { xs: 2, md: 4 },
    py: 2,
  }}
>
  <Box
    sx={{
      flex: '0 1 auto', 
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center', 
    }}
  >
    <img
      src={footerImg}
      alt="footerLogo"
      style={{ minWidth: '76px', height: 'auto' }}
    />
  </Box>

 
  <Typography
    sx={{
      flex: '1 1 auto', // Allow the text to grow/shrink
      color: '#969BA0',
      fontSize: '12px',
      textAlign: 'justify',
      maxWidth: { xs: '100%', sm: '70%' }, // Adjust width for small screens
      wordBreak: 'break-word', // Handle long text gracefully
    }}
  >
    Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
    consectetur eget erat nec. Molestie mattis adipiscing sit condimentum.
    Pellentesque donec suscipit amet rhoncus eget ac purus. Nec diam
    mauris et aliquet sit commodo sagittis.
  </Typography>
</Box>

    </Box>
  );
};

export default WebshopMain;

