import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  Grid,
  Grid2,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import PickupType from "./Checkout";
import Checkout from "./Checkout";
import { useDispatch, useSelector } from "react-redux";
import { deleteAddToCartProducts } from "../../store/slice/Webshop.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const badgeItem=['Beef','Beef','Grilled','Grilled','Crispy','Crispy','Crispy']
const badgeItem = [
  {
    id: 1,
    name: "Beef",
    color: "#00DC1B",
  },
  {
    id: 2,
    name: "Beef",
    color: "#00DC1B",
  },
  {
    id: 3,
    name: "Grilled",
    color: "#9600DC",
  },
  {
    id: 4,
    name: "Grilled",
    color: "#9600DC",
  },
  {
    id: 5,
    name: "Crispy",
    color: "#F3D120",
  },
  {
    id: 6,
    name: "Crispy",
    color: "#F3D120",
  },
  {
    id: 7,
    name: "Crispy",
    color: "#F3D120",
  },
];

export default function DeletePopup({ open, handleClose,cartClose }) {
  const dispatch=useDispatch()
  const{selectedDeleteItem,addToCartProducts}=useSelector((store)=>store?.Webshop)
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [openPickupType,setPickUpType]=React.useState(false)
  const handleDelete=()=>{
    if(addToCartProducts?.length <= 1){
      cartClose()
    }
    dispatch(deleteAddToCartProducts(selectedDeleteItem))
    handleClose()
    
  }
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        // PaperProps={{
        //   sx: {
        //     width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
        //     maxWidth: "none", // Prevents default Material-UI maxWidth constraints
        //     height: "auto", // Allows height to adjust dynamically
        //     minHeight:'500px'
        //   },
        // }}
      >
        <Box>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{mt:2}}>
            <Box sx={{display:"flex",flexDirection:'column'}}>
                <Typography sx={{fontWeight:"bold",fontSize:"26px",textAlign:'center'}}>
                    Are you Sure ?
                </Typography >
                <Typography sx={{color:"#969BA0",fontSize:"1.5rem",textAlign:'center'}}>Are your sure, you want to delete this item.</Typography>
                <Box sx={{mt:2,display:'flex',gap:"1rem",justifyContent:'center',alignItems:'center'}}>
                    <Button variant="contained" sx={{backgroundColor:"#969BA0",color:"#fff"}} onClick={handleDelete}>
                        Yes
                    </Button>
                    <Button variant="contained" sx={{backgroundColor:"#FF0000",color:"#fff"}} onClick={handleClose}>
                        No
                    </Button>
                </Box>
            </Box>
         
          
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
      <Checkout open={openPickupType} handleClose={()=>setPickUpType(false)} setOpen={setPickUpType} />
    </React.Fragment>
  );
}