
import { BootstrapDialog } from './KioskAddToCart';
import * as React from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/material";
import KioskMenuListCardsComponent from './KioskMenuListCard';


const KioskRecommendationProduct = ({recommendationOpen,handleRecommendationClose,nextProduct,handleNo,setNextProduct,handleYes,recommendedProducts}) => {

  return (
    <BootstrapDialog
        open={recommendationOpen}
        onClose={handleRecommendationClose}
        aria-labelledby="recommendation-dialog-title"
        
        PaperProps={{
          sx: {
            width: !nextProduct ? "30%" : "90%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
          },
        }}
      >
        <DialogTitle
          id="recommendation-dialog-title1"
          sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem" }}
        >
          Recommended Product
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            handleNo();
            setNextProduct(false);
          }}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          {nextProduct ? (
            <Box sx={{ width: "100%" }}>
              <KioskMenuListCardsComponent data={recommendedProducts} recommededProducts={true}/>
            </Box>
          ) : (
            <Typography sx={{ textAlign: "center", color: "grey" }}>
              Would you like to explore our recommended product?
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              !nextProduct ? handleYes() : handleNo();
            }}
            sx={{ backgroundColor: "#F37120", color: "#fff" }}
          >
            {!nextProduct ? "Yes" : "Next"}
          </Button>
          {!nextProduct && (
            <Button
              onClick={handleNo}
              sx={{ backgroundColor: "grey", color: "#fff" }}
            >
              No
            </Button>
          )}
        </DialogActions>
      </BootstrapDialog>
    
  )
}

export default KioskRecommendationProduct
