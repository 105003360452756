import axios from "axios";
import apiEndpoints from "../config/apiEndpoints";

const FetchWebshopSettings=async(key)=>{
    try {
        const response=await axios.get(apiEndpoints?.GET_WEBSHOP_WIDGET_SETTINGS(key));
        return response   
    } catch (error) {
        console.error("Error fetching webshop products info", error);
        return null;    
    }
}

const FetchWebShopCategories=async(key,lang)=>{
    try {
        const response=await axios.get(apiEndpoints?.GET_WEBSHOP_CATEGORIES(key,lang));
        return response
        
    } catch (error) {
        console.error("Error fetching webshop categories", error);
        return null;    
    }
}

const FetchWebShopProductList=async(key,lang,categoryId)=>{
    try {
        const response=await axios.get(apiEndpoints?.GET_WEBSHOP_PRODUCTSLIST(key,lang,categoryId));
        return response
        
    } catch (error) {
        console.error("Error fetching webshop productlist", error);
        return null;    
    }
}

const FetchWebshopProductsInfo=async(key,lang,productId)=>{
    try {
        const response=await axios.get(apiEndpoints?.GET_WEBSHOP_PRODUCT_DETAILS(key,lang,productId));
        return response   
    } catch (error) {
        console.error("Error fetching webshop products info", error);
        return null;    
    }
}

const CreateWebShopOrder=async(data)=>{
    try {
        const response=await axios.post(apiEndpoints?.CREATE_WEBSHOP_ORDER,data);
        return response      
    } catch (error) {
        console.error("Error creating webshop order", error);
        return null;
    }
}

const createGuest=async(payload)=>{
    try {
        const response=await axios.post(apiEndpoints?.CREATE_GUEST,payload);
        return response      
    } catch (error) {
        console.error("Error creating guest", error);
        return null;
    }
}

export {
    FetchWebShopCategories,
    FetchWebShopProductList,
    FetchWebshopProductsInfo,
    CreateWebShopOrder,
    FetchWebshopSettings,
    createGuest
}
