import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  Popover,
  Box,
  Typography,
  Button,
  Grid,
  Skeleton,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import restype from "../../assets/restype.png";
import {
  setExtraProductData,
  setGuestCount,
  setIsBackToHoreca,
  setQuantities,
  setSelectedExtraProduct,
  updateReservationGroup,
  updateReservationType,
  updateSelectedDate,
  updateTime,
  updateTotalCount,
} from "../../store/slice/Addguest.slice";
import { getExtraProductDetails } from "../../api/HorecaWidget.service";

const ReservationType = ({ Ref, isOpen, handleClose, isLoading }) => {
  const theme = useTheme();
  const [isImageValid, setIsImageValid] = useState(true); // Track image validity
  const matchesXs = useMediaQuery(theme.breakpoints.down("xs")); // For small devices
  const matchesSm = useMediaQuery(theme.breakpoints.down("sm")); // For tablets and up
  const { reservationTypeDatas, reservType, time, reservGroup, selectedExtraProduct, quantities, isBackToHoreca, languageId, defaultLanguage, languageWords } = useSelector(
    (store) => store?.addGuest
  );
  const dispatch = useDispatch();

  const fetchExtraProducts = async () => {
    try {
      const response = await getExtraProductDetails(
        reservType?.idreservationtype, defaultLanguage
      );
      if(response?.status != 200 || !response?.data){
        throw new Error('Could not fetch extra product details for reservation type ' + response)
      }
      dispatch(setExtraProductData(response?.data));
    } catch (error) {
      console.error("error extra product", error?.message);
    }
  };

  useEffect(() => {
    if (reservType?.idreservationtype != null && !isBackToHoreca) {
      fetchExtraProducts();
    }
  }, [reservType?.idreservationtype, isBackToHoreca, defaultLanguage]);

  useEffect(() => {
    if (reservationTypeDatas?.length === 1) {
      dispatch(updateReservationType(reservationTypeDatas[0]));
    }
  }, [reservationTypeDatas]);

  return (
    <Popover
      open={isOpen}
      anchorEl={Ref}
      onClose={handleClose}
    
      PaperProps={{
        sx: {
          width: matchesSm ? "93%"  : "430px",
          maxWidth: "430px",
        
         
        },
      }}
    >
      <Box
        sx={{
          backgroundColor: theme?.resType?.bgColor,
          width: { xs: "100%", sm: '440px', md: "440px" }, // Responsive width
          p: 3,
          display: "flex",
          flexDirection: "column",
         // justifyContent: "center",
          alignItems: "center",
          gap: "1rem",
          height:'',
          minHeight:"250px"
        }}
      >
        {isLoading ? (
          // Shimmer loading effect
          Array.from(new Array(3)).map((_, i) => (
            <Box
              key={i}
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                gap: "0.5rem",
                backgroundColor: "#EBEBEB",
                border: "1px solid #EBEBEB",
                p: 1,
                borderRadius: "8px",
                width: { xs: "100%", sm: "440px", md: "440px" }, // Dynamic width for responsiveness
              }}
            >
              <Grid container>
                <Grid item xs={12} sm={12} md={4}>
                  <Skeleton variant="rectangular" height={110} />
                </Grid>
                <Grid item xs={12} sm={12} md={8}>
                  <Box sx={{ p: 1, width: "100%" }}>
                    <Skeleton />
                    <Skeleton width="60%" />
                    <Skeleton width="80%" />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          ))
        ) : reservationTypeDatas?.length <= 0 ? (
          <Typography
            variant="body2"
            sx={{ textAlign: "center", color: "grey", fontWeight: "medium" }}
          >
            No reservation type available.
          </Typography>
        ) : (
          reservationTypeDatas.map((item, i) => {
            const nameTranslation = item.reservationtypeLanguages.find(
              (lang) => lang.fieldname === "name" && lang.idlanguage === languageId
            );
            const infoTranslation = item.reservationtypeLanguages.find(
              (lang) => lang.fieldname === "info" && lang.idlanguage === languageId
            );


            return (
  

              <Box
                key={i}
                sx={{
                  display: "flex",
                  flexDirection: { xs: "row" }, // Responsive flexDirection
                  gap: "0.5rem",
                  backgroundColor:
                    reservType?.name === item?.name
                      ? theme?.resType?.itemBgActive
                      : theme?.resType?.itemBgColor,
                  border: `1px solid ${theme?.resType?.borderColor}`,
                  p: 2,
                  borderRadius: "8px",
                  cursor: "pointer",
                  color:
                    reservType?.name === item?.name
                      ? theme?.resType?.textColorActive
                      : theme?.resType?.itemTextColor,
                  width: "100%" , // Adjust width for small screens
                  "&:hover": {
                    backgroundColor: theme?.resType?.itemBgHover,
                    color: theme?.resType?.textHover,
                  },
                }}
                onClick={() => {
                  dispatch(setIsBackToHoreca(false));
                  if (selectedExtraProduct?.length > 0) {
                    dispatch(setSelectedExtraProduct([]));
                  }
                  if (Object?.keys(quantities)?.length > 0) {
                    dispatch(setQuantities({}));
                  }
                  if (Object?.keys(reservGroup)?.length > 0) {
                    dispatch(updateReservationGroup({}));
                  }
                  if (time?.trim() !== "") {
                    dispatch(updateTime(""));
                  }
                  dispatch(updateReservationType(item));
                  handleClose();
                }}
              >
                <Grid container spacing={1} sx={{position:"relative"}}>
                <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          width: "100%",
                          p:1
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            fontWeight: "600",
                          }}
                        >
                          {nameTranslation?.data || item?.name}
                        </Typography>
                        {item?.totalCost > 0 && (
                          <Typography
                            sx={{
                              fontSize: "14px",
                              fontWeight: "600",
                            }}
                          >
                            €{parseFloat(item?.totalCost).toFixed(2)}
                          </Typography>
                        )}
                      </Box>
                      {item?.imageFileName && isImageValid && (
    <Grid item xs={4} sm={4} md={4}>
      <Box
        sx={{
          width: "100%",
          height: { xs: "auto" },
          display: "flex",
          justifyContent: "center",
          borderRadius: "8px",
          height:"100%"
        }}
      >
        <img
          src={item?.imageFileName}
          alt="res-type-img"
          style={{
            width: "100%",
            height: "100%",
            objectFit: "none",
            borderRadius: "8px",
          }}
          onLoad={() => {
            setIsImageValid(true); // Confirm valid image
          }}
          onError={(error) => {
            setIsImageValid(false); // Mark image as invalid
          }}
        />
      </Box>
    </Grid>
   )} 
                  <Grid item xs={8} sm={8} md={8} >
                    <Box sx={{  width: "100%",minHeight: "50px",display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
               
                      <Typography
                        sx={{
                          fontSize: "1rem",
                          mt: 1,
                          wordBreak: "break-word", // Ensures words wrap within the container
                          overflow: "hidden",       // Hides any overflow text
                          textOverflow: "ellipsis", // Adds an ellipsis for overflowed text
                          whiteSpace: "normal",     // Allows the text to wrap
                          mb:2
                        }}
                      >
                        {infoTranslation?.data || item?.info}
                      
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          width:"100%",
                          justifyContent: "flex-end",
                          alignItems:'flex-end',
                          mt: "auto", // Pushes the button to the bottom
                          position:"absolute",
                          bottom:0,
                        
                          right:0,
                   
                        
                        }}
                      >
                        <Button
                          variant="contained"
                          size="small"
                          sx={{
                            backgroundColor:
                              reservType?.name === item?.name
                                ? theme?.resType?.chooseBgActive
                                : theme?.resType?.chooseBg,
                            color:
                              reservType?.name === item?.name
                                ? theme?.resType?.chooseTextActive
                                : theme?.resType?.chooseText,
                            "&:hover": {
                              backgroundColor: theme?.resType?.chooseBgHover,
                              color: theme?.resType?.chooseTextHover,
                            },
                            width: "60px",
                            height: "30px",
                            fontSize: "10px",
                            borderRadius: "7px",
                          }}
                        >
                          {languageWords?.choose}
                        </Button>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            );
          })
        )}
      </Box>
    </Popover>
  );
};

export default ReservationType;


