import { Box, Grid2, Typography } from '@mui/material'
import React from 'react'
import img1 from "../../assets/TVfood1.png"
import img2 from "../../assets/TVfood2.png"

const TVWidget = () => {
  return (
    <Box sx={{p:2}}>
       <Grid2 container spacing={2}>
        <Grid2 size={{md:4}}>
        <Box sx={{display:"flex",gap:"1rem",alignItems:"center",px:2,width:"400px",height:'130px',backgroundColor:"#F37120",borderRadius:"8px"}}>
            <Box sx={{width:"75px",height:"75px"}}> 
            <img src={img1} style={{width:"100%",height:"100%"}}/>
            </Box>    
            <Typography sx={{color:"#fff",fontSize:"2rem"}}>Preparing</Typography>
        </Box>
        <Box>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mt:2,mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",backgroundColor:"#FFF8F6"}}>0009</Typography>
        </Box>

        </Grid2>
        
        <Grid2 size={{md:4}}>
        <Box sx={{display:"flex",gap:"1rem",alignItems:"center",width:"400px",px:2,height:'130px',backgroundColor:"#F37120",borderRadius:"8px"}}>
            <Box sx={{width:"75px",height:"75px"}}> 
            <img src={img2} style={{width:"100%",height:"100%"}}/>
            </Box>
            
            <Typography sx={{color:"#fff",fontSize:"2rem"}}>Collect</Typography>
        </Box>
        <Box>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mt:2,mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",mb:2,backgroundColor:"#FFF8F6"}}>0009</Typography>
            <Typography sx={{px:2,py:1,border:"1px dashed #F37120",color:"#00000",width:"120px",textAlign:'center',borderRadius:"6px",backgroundColor:"#FFF8F6"}}>0009</Typography>
        </Box>

        </Grid2>
    
      

       </Grid2>
      
    </Box>
  )
}

export default TVWidget
