import { Box, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import foodImg from "../../assets/mdi_food.png";
import scooterImg from "../../assets/scooter.png";
import dineIn from "../../assets/dinein.png";
import takeWay from "../../assets/takeway.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LanguageMenuItem from "../../components/SelectComponent/LanguageMenuItem";
import {
  setLanguageData,
  updateDefaultLanguage,
  updateDeliveryType,
} from "../../store/slice/Webshop.slice";

const DeliveryTypeScreen = () => {
  const [startHere, setStartHere] = useState(true);
  const dispatch = useDispatch();
  const [deliveryType, setDelvieryType] = useState("");
  const navigate = useNavigate();
  const { languageData, defaultLanguage,shopType,webshopSettings,webshopLoading} = useSelector(
    (store) => store?.Webshop
  );

  
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <LanguageMenuItem
          languageData={languageData}
          updateLanguageData={setLanguageData}
          defaultLanguage={defaultLanguage}
          updateDefaultLanguage={updateDefaultLanguage}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {/* Main Content */}
        <Box>
          {/* Header Section */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              px: 2,
            }}
          >
            <Typography
              sx={{
                textAlign: "center",
                color: "#F37120",
                fontSize: { xs: "24px", md: "40px" },
                fontWeight: 600,
                mb: 1,
              }}
            >
              {webshopSettings?.webshopWidgetSetupLanguages?.find((item)=>item?.fieldname == 'PickupOrDeliveryScreenHeading')?.data}
            </Typography>
            <Typography
              sx={{
                width: { md: 650, xs: "100%" },
                textAlign: "center",
                fontSize: "16px",
                color: "#969BA0",
                lineHeight: "1.5rem",
              }}
            >
               {webshopSettings?.webshopWidgetSetupLanguages?.find((item)=>item?.fieldname == 'PickupOrDeliveryScreenInfo')?.data}
            </Typography>
          </Box>

          {/* kiosk start type true */}
          {startHere && shopType === "kiosk" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                mt: 2,
                width: "100%", // Full width to center horizontally
              }}
            >
              <Box
                sx={{
                  width: "286px",
                  height: "324px",
                  backgroundColor: !startHere ? "#F37120" : "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setStartHere(false);
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFF8F6",
                    width: "90%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                  }}
                >
                  <img
                    style={{ width: "150px", height: "150px" }}
                    src={foodImg}
                    alt="food-img"
                  />
                </Box>
                <Typography
                  sx={{
                    color: !startHere ? "#fff" : "#000",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: 2,
                  }}
                >
                  Start Here
                </Typography>
              </Box>
            </Box>
          )}

          {/* kiosk choose type */}
          {!startHere && shopType == "kiosk" && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: { xs: "1.5rem", md: "3rem" },
                width: "100%",
                mt: 7,
                pb: 6, // Add padding to avoid overlap with footer
              }}
            >
              {/* Dine In */}
              <Box
                sx={{
                  width: "286px",
                  height: "324px",
                  backgroundColor:
                    deliveryType == "DineIn" ? "#F37120" : "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(updateDeliveryType("DineIn"));
                  navigate(`/webshopMenuItem`);
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFF8F6",
                    width: "90%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                  }}
                >
                  <img
                    style={{ width: "150px", height: "150px" }}
                    src={dineIn}
                    alt="dine-img"
                  />
                </Box>
                <Typography
                  sx={{
                    color: deliveryType == "DineIn" ? "#fff" : "#000",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: 2,
                  }}
                >
                  Dine In
                </Typography>
              </Box>

              {/* Take a way*/}
              <Box
                sx={{
                  width: "286px",
                  height: "324px",
                  backgroundColor:
                    deliveryType == "Takeaway" ? "#F37120" : "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(updateDeliveryType("Takeaway"));
                  navigate(`/webshopMenuItem`);
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFF8F6",
                    width: "90%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                  }}
                >
                  <img
                    style={{ width: "150px", height: "150px" }}
                    src={takeWay}
                    alt="takeway-img"
                  />
                </Box>
                <Typography
                  sx={{
                    color: deliveryType == "Takeaway" ? "#fff" : "#000",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: 2,
                  }}
                >
                  Take a Way
                </Typography>
              </Box>
            </Box>
          )}

          {/* webshop  */}
          {shopType != "kiosk" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexWrap: "wrap",
                gap: { xs: "1.5rem", md: "3rem" },
                width: "100%",
                mt: 7,
                pb: 6, // Add padding to avoid overlap with footer
              }}
            >
              {/* Pickup Card */}
              <Box
                sx={{
                  width: { xs: "90%", sm: "286px" },
                  height: "324px",
                  backgroundColor:
                    deliveryType == "pickup" ? "#F37120" : "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(updateDeliveryType("pickup"));
                  navigate(`/webshopMenuItem`);
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFF8F6",
                    width: "90%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                  }}
                >
                  <img
                    style={{ width: "150px", height: "150px" }}
                    src={foodImg}
                    alt="food-img"
                  />
                </Box>
                <Typography
                  sx={{
                    color: deliveryType == "pickup" ? "#fff" : "#000",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: 2,
                  }}
                >
                  Pickup
                </Typography>
              </Box>

              {/* Delivery Card */}
              <Box
                sx={{
                  width: { xs: "90%", sm: "286px" },
                  height: "324px",
                  backgroundColor:
                    deliveryType == "delivery" ? "#F37120" : "#fff",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "18px",
                  boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  cursor: "pointer",
                }}
                onClick={() => {
                  dispatch(updateDeliveryType("delivery"));
                  navigate(`/webshopMenuItem`);
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#FFF8F6",
                    width: "90%",
                    height: "80%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "18px",
                  }}
                >
                  <img
                    style={{ width: "150px", height: "150px" }}
                    src={scooterImg}
                    alt="scooter-img"
                  />
                </Box>
                <Typography
                  sx={{
                    color: deliveryType == "delivery" ? "#fff" : "#000",
                    fontSize: "20px",
                    fontWeight: 600,
                    mt: 2,
                  }}
                >
                  Delivery
                </Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default DeliveryTypeScreen;
