import { Box, Typography } from "@mui/material";
import { useState } from "react";

const ExpandableText = ({ text }) => {
    const [expanded, setExpanded] = useState(false);
  
    const handleToggle = () => {
      setExpanded((prev) => !prev);
    };
  
    return (
      <Box>
        <Typography
          variant="body2"
          color="textSecondary"
          mb={2}
          sx={{
            display: "-webkit-box",
            WebkitBoxOrient: "vertical",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: expanded ? "none" : 3,
            cursor: "pointer",
          }}
          onClick={handleToggle}
        >
          {text}
        </Typography>
        {expanded && (
          <Typography
            variant="body2"
            color="primary"
            sx={{ cursor: "pointer" }}
            onClick={handleToggle}
          >
            Show less
          </Typography>
        )}
      </Box>
    );
  };
export default  ExpandableText