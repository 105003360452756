import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  Grid,
  Grid2,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import PickupType from "./Checkout";
import Checkout from "./Checkout";
import DeletePopup from "./Deletepopup";
import PaymentMethod from "./PaymentMethod";
import { useDispatch, useSelector } from "react-redux";
import { IMG_BASE_URL, format_Number } from "../../utils/utils";
import { UpdateAddToCartProducts, deleteAddToCartProducts, setItemCount, setSelectedDeleteItem, setSupplementInfo, setTotalProductCount } from "../../store/slice/Webshop.slice";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

// const badgeItem=['Beef','Beef','Grilled','Grilled','Crispy','Crispy','Crispy']
const badgeItem = [
  {
    id: 1,
    name: "Beef",
    color: "#00DC1B",
  },
  {
    id: 2,
    name: "Beef",
    color: "#00DC1B",
  },
  {
    id: 3,
    name: "Grilled",
    color: "#9600DC",
  },
  {
    id: 4,
    name: "Grilled",
    color: "#9600DC",
  },
  {
    id: 5,
    name: "Crispy",
    color: "#F3D120",
  },
  {
    id: 6,
    name: "Crispy",
    color: "#F3D120",
  },
  {
    id: 7,
    name: "Crispy",
    color: "#F3D120",
  },
];

export default function Cartdetails({ open, handleClose }) {
  const {
    shopType,
    webShopProductInfo,
    itemCount,
    supplementsInfo,
    selectedProduct,
    addToCartProducts,
    webshopSettings,
    deliveryType
  } = useSelector((store) => store?.Webshop);
  const [itemCounts, setItemCounts] = React.useState(itemCount);
  const dispatch=useDispatch()
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [openPickupType,setPickUpType]=React.useState(false);
  const[opendelete,setOpenDelete]=React.useState(false)
  const [openPaymentMethod,setOpenPaymentMethod]=React.useState(false);
 


  const handleCheckout=()=>{
    
    shopType == 'kiosk' ?  setOpenPaymentMethod(true) : setPickUpType(true);
    handleClose() 
  }
    
  React.useEffect(() => {
 
    dispatch(setItemCount(itemCounts));
    // const totalCountProducts = Object.values(itemCounts).reduce((sum, value) => sum + value, 0);
    const totalCountProducts=addToCartProducts?.reduce((sum, value) => {
      return sum + itemCounts[value.idproduct]
    }, 0)
    console.log("totalCountProducts",totalCountProducts)
    dispatch(setTotalProductCount(totalCountProducts))
  }, [itemCounts]);

  const handleDelete=(id)=>{
   dispatch(setSelectedDeleteItem(id))
    setOpenDelete(true)
    // dispatch(deleteAddToCartProducts(id))
   
  }

  function CalculateVat(price, vatPersentage) {

    const vat =  (price / (vatPersentage + 100)) * vatPersentage 
    return Math.round(vat * 100) / 100;

}


const calculateTotalPrice = (onlyVat=false) => {
  let vatAmount;
  const totalProductsPrice = addToCartProducts?.reduce((acc, product) => {
    // Find supplements info for the product
    const supplementInfo = supplementsInfo?.find(
      (item) => item?.idproduct === product.idproduct
    );

    // Calculate the total price of supplements for this product
    const supplementTotal = supplementInfo?.supplements?.reduce(
      (suppAcc, supplement) => suppAcc + (supplement?.price || 0),
      0
    ) || 0;
    // Calculate the total price for the product including supplements
    const productTotal = product.price * (itemCount[product.idproduct] || 0) + supplementTotal;

    // Add the VAT for this product
    vatAmount =onlyVat || webshopSettings?.pricesIncludingVAT  ? CalculateVat(productTotal, product?.vat || 0)  : 0 ;
    return acc + productTotal + vatAmount; ;
  }, 0);
  if(onlyVat){
    return format_Number(vatAmount);
  }
  return format_Number(totalProductsPrice);
};

const ProductPrice = (id, productPrice) => {
  // Find supplements info for the product
  const supplementInfo = supplementsInfo?.find(
    (item) => item?.idproduct == id
  );

  // Calculate the total price of supplements for this product
  const supplementTotal =
    supplementInfo?.supplements?.reduce(
      (suppAcc, supplement) => suppAcc + (supplement?.price || 0),
      0
    ) || 0;
  // Calculate the total price for the product including supplements
  const productTotal = productPrice * (itemCount[id] || 0) + supplementTotal;

  return format_Number(productTotal)
};

  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose
        }
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen || isMediumScreen ? "90%" : "60%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
            minHeight:'500px'
          },
        }}
      >
        <Box>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{ color: "#F37120", fontWeight: "bold", fontSize: "1.2rem",mb:3 }}
          >
            Cart details
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{mt:2}}>
          {
            addToCartProducts?.map((item,index)=>(
              <Grid2 container key={index} spacing={1} alignItems={'flex-start'} >
              <Grid2 size={{ xs: 3,sm:2 }}>
                <Box sx={{ width: "100%", borderRadius: 8 }}>
                  <img
                    style={{ width: "100%", borderRadius: 8 }}
                    src={`${IMG_BASE_URL}/upload/WebshopProduct${item?.imagefilename?.replace(
                      /\\/g,
                      "/"
                    )}`}
                    alt="cartimg"
                  />
                </Box>
              </Grid2>
              <Grid2 size={{ xs:6,sm:7 }}>
                <Box>
                  <Typography
                    sx={{ color: "#000000", fontWeight: "600",fontSize: isSmallScreen ? "0.8rem" : "1.2rem" }}
                  >
                    {item?.displayname || item?.displayName}
                  </Typography>
                  <Box
                    sx={{ display: "flex", gap: isSmallScreen ? "0.3rem" : "1rem", flexWrap: "wrap" }}
                  >
                    {supplementsInfo?.find((supp)=>supp?.idproduct == item?.idproduct)?.supplements?.map((suppItem) => (
                       <small
                       style={{color:"grey"}}
                      //  variant="outlined"
                      //  sx={{
                      //    padding: "4px 8px",
                      //    border: `1px solid ${
                      //      supplementsInfo?.find((supp) => supp?.idProduct === item?.idProduct)?.detailsKey ===
                      //      'supplementProductsDetails1'
                      //        ? '#00DC1B'
                      //        : supplementsInfo?.find((supp) => supp?.idProduct === item?.idProduct)?.detailsKey ===
                      //          'supplementProductsDetails2'
                      //        ? '#F3D120'
                      //        : '#9600DC'
                      //    }`,
                      //    borderRadius: "8px",
                      //    color: supplementsInfo?.find((supp) => supp?.idProduct === item?.idProduct)?.detailsKey ===
                      //    'supplementProductsDetails1'
                      //      ? '#00DC1B'
                      //      : supplementsInfo?.find((supp) => supp?.idProduct === item?.idProduct)?.detailsKey ===
                      //        'supplementProductsDetails2'
                      //      ? '#F3D120'
                      //      : '#9600DC',
                      //    fontSize: { xs: "0.6rem", md: "14px" },
                      // //   width: { md: "68px", xs: "45px" },
                      //  }}
                     >
                       {suppItem?.displayName}
                     </small>
                    ))}
                  </Box>
                  {isSmallScreen && <Typography sx={{mt:2,color:"#F37120"}}>€{ProductPrice(item?.idproduct,item?.price)}</Typography>}
                </Box>
              </Grid2>
              <Grid2 size={{ xs: 2 }}>
              <Select
  sx={{ width: isSmallScreen ? "100%" : "60%", height: "45px" }}
  name={item?.idproduct}
  defaultValue="" // Optional: set a default value
  value={itemCount[item?.idproduct] || ""} // Ensure controlled component
  onChange={(e) => {
    const newCount = parseInt(e.target.value, 10); // Convert value to number
    // setItemCounts((prevCounts) => ({
    //   ...prevCounts,
    //   [item?.idproduct]: newCount, // Update only the specific item
    // }));
    setItemCounts({...itemCount, [item?.idproduct]: newCount})
  }}
>
  {Array.from({ length: webshopSettings?.maxQuantityOfEachProduct || 10 }, (_, i) => i + 1).map((number) => (
    <MenuItem key={number} value={number}>
      {number} {/* Render numbers from 1 to maxQuantityOfEachProduct */}
    </MenuItem>
  ))}
</Select>
          </Grid2>
          <Grid2 size={{ xs: 1}} >
            <Box sx={{display:'flex',justifyContent:"space-evenly",alignItems:'center'}}>
              {!isSmallScreen && <Typography sx={{color:"#F37120"}}>€{ProductPrice(item?.idproduct,item?.price)}</Typography>}
              <IconButton
                aria-label="delete-icon"
                // onClick={handleClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={()=>handleDelete(item?.idproduct)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid2>
         
          {/* <Grid2 size={{ md: 4 }}>
          <Select
  sx={{ width: "153px", height: "45px" }}
  defaultValue="" // Optional: set a default value
  displayEmpty
>
  <MenuItem value="" disabled>
    qty
  </MenuItem>
  {Array.from({ length: 10 }, (_, i) => i + 1).map((number) => (
    <MenuItem key={number} value={number}>
      {number}
    </MenuItem>
  ))}
</Select>
          </Grid2>
          <Grid2 size={{ md: 1 }} >
            <Box sx={{display:'flex',justifyContent:"space-evenly",alignItems:'center'}}>
              <Typography>€39.00</Typography>
              <IconButton
                aria-label="delete-icon"
                // onClick={handleClose}
                sx={{
                  color: (theme) => theme.palette.grey[500],
                }}
                onClick={()=>setOpenDelete(true)}
              >
                <DeleteIcon />
              </IconButton>
            </Box>
          </Grid2> */}
        
        </Grid2>

            ))
          }
        
          <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end', // Align items to the right end
      marginTop: '1rem',
      mr:2,
      mt:5
      
    }}
  >
    <Box sx={{ display: 'flex',gap:"3rem",mb:2, alignItems: 'center' }}>
      <Typography>Total</Typography>
      <Typography>€{calculateTotalPrice()}</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: "3rem",mb:2, alignItems: 'center' }}>
      <Typography>VAT</Typography>
      <Typography>€{calculateTotalPrice(true)}</Typography>
    </Box>
    <Box sx={{ display: 'flex', gap: "3rem", alignItems: 'center',borderTop:"1px solid black",pt:2 }}>
      <Typography sx={{fontWeight:"bold"}}>Total Including VAT</Typography>
      <Typography sx={{fontWeight:"bold"}}>€{calculateTotalPrice()}</Typography>
    </Box>
    </Box>
    <Grid2 container spacing={2} sx={{pt:2,width:'100%'}} >
        <Grid2 size={{md:6,sm:12,xs:12}} justifyContent={'center'} >
            <Button variant="contained"  size={isSmallScreen ? "small" : "large"} fullWidth sx={{backgroundColor:"#F37120", color:"#fff"}} onClick={handleClose}>
                Proceed Shopping
            </Button>
        </Grid2>
        <Grid2 size={{md:6,sm:12,xs:12}} >
        <Button variant="contained"  size={isSmallScreen ? "small" : "large"} fullWidth sx={{  backgroundColor:"#F37120", color:"#fff"}} onClick={handleCheckout}>
        Checkout €{calculateTotalPrice()}
            </Button>
        </Grid2>
    </Grid2>
          
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
      <Checkout open={openPickupType} handleClose={()=>setPickUpType(false)} setOpen={setPickUpType} />
      <DeletePopup open={opendelete} handleClose={()=>setOpenDelete(false)} cartClose={handleClose} />
      <PaymentMethod open={openPaymentMethod} handleClose={()=>setOpenPaymentMethod(false)} />
    </React.Fragment>
  );
}
