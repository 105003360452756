import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Popover,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import dayjs from "dayjs";
import CloseIcon from "@mui/icons-material/Close";
import clock from "../../assets/clock.png";
import {
  FetchWidgetConfigDetails,
  GetOpeningHours,
  getLanguageDetails,
} from "../../api/HorecaWidget.service";
import { useLocation, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  SetUpateOpeningHours,
  setLanguageID,
  setLanguageWords,
  updateDefaultLanguage,
} from "../../store/slice/Addguest.slice";
import LanguageMenuItem from "../../components/SelectComponent/LanguageMenuItem";
import {
  setLanguageLoaded,
  updateLanguageData,
  updateWidgetConfig,
  updateWidgetUrl,
} from "../../store/slice/widgetSetting.slice";
import AccessTimeIcon from "@mui/icons-material/AccessTime";

const OpeningHoursCalendar = ({ widgetConfig }) => {

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
    const widgetSetupId = queryParams.get("widgetsetupid");
  const { OpeningHoursWidget, defaultLanguage, languageWords } = useSelector(
    (store) => store?.addGuest
  );
  const { widgetUrl } = useSelector((store) => store?.widgetSettings);
  const [langLoading, setLangLoading] = useState(false);
  const [loadingOpeningHours, setLoadingOpeningHours] = useState(false);
  const dispatch = useDispatch();
  const { key } = useParams(); // Retrieve the key from the URL
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm")); // For xs and sm
  const isMediumOrLargeScreen = useMediaQuery(theme.breakpoints.up("md")); // For md and lg
  const [selectedYear, setSelectedYear] = useState(2024);
  const [selectedMonth, setSelectedMonth] = useState(dayjs().month()); // Initial current month
  const [selectedDate, setSelectedDate] = useState({
    year: 2024,
    month: dayjs().month(),
    day: null,
  }); // Selected date

  const daysOfWeek = [
    languageWords?.monday?.slice(0, 2),
    languageWords?.Tuesday?.slice(0, 2),
    languageWords?.Wednesday?.slice(0, 2),
    languageWords?.Thursday?.slice(0, 2),
    languageWords?.Friday?.slice(0, 2),
    languageWords?.Saturday?.slice(0, 2),
    languageWords?.Sunday?.slice(0, 2),
  ];
  const [selectedOpeningHours, seSelecteOpeningHours] = useState({});
  const [widgetLoading, setWidgetLoading] = useState(false);
  const months = [
    languageWords?.January,
    languageWords?.February,
    languageWords?.March,
    languageWords?.April,
    languageWords?.May,
    languageWords?.June,
    languageWords?.July,
    languageWords?.August,
    languageWords?.September,
    languageWords?.October,
    languageWords?.November,
    languageWords?.December,
  ];
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;


    // get the height of the widget
    const getHeight = () => {
        setTimeout(() => {
            let rootHeight = document.getElementsByClassName("MuiBox-root")[1];
            let frameHeight = 200;
            if (rootHeight) {

                if (rootHeight.clientHeight > frameHeight) {

                    if (rootHeight.clientWidth < 700) {
                        frameHeight = rootHeight.clientHeight + 100;
                    }
                    else {
                        frameHeight = rootHeight.clientHeight + 20;
                    }
                }

                    if (frameHeight < 480) {
                        frameHeight = 480;
                    }
            }
            send(
                frameHeight + "px",
                "100%",
                "",
                "0px",
                "0px",
                "",
                widgetConfig?.settings?.floatButtonActive
            )


        }, 100);

    };

    // get the height of the MuiBox-root after loading the full page
    useEffect(() => {

        console.log(widgetSetupId);
        console.log(widgetSetupId);

            getHeight();
    });




  // Function to get number of days in the current month
  const daysInMonth = (monthIndex, year) => {
    return dayjs(`${year}-${monthIndex + 1}-01`).daysInMonth();
  };

  // Handlers for changing the month and year
  const handlePreviousMonth = () => {
    console.log("selectedMonth", selectedMonth);

    if (selectedMonth === 0) {
      setSelectedMonth(11); // Move to December of previous year
      setSelectedYear(selectedYear - 1);
    } else {
      setSelectedMonth(selectedMonth - 1);
    }
  };

  const handleNextMonth = () => {
    if (selectedMonth === 11) {
      setSelectedMonth(0); // Move to January of next year
      setSelectedYear(selectedYear + 1);
    } else {
      setSelectedMonth(selectedMonth + 1);
    }
  };

  const send = (
    frameHeight,
    frameWidth,
    top,
    right,
    bottom,
    left,
    floating,
    redirectUrl
  ) => {
    window.parent.postMessage(
      {
        reservationIframeHeight: frameHeight,
        reservationIframeWidth: frameWidth,
        reservationIframePositionTop: top,
        reservationIframePositionRight: right,
        reservationIframePositionBottom: bottom,
        reservationIframePositionLeft: left,
        reservationIframePositionFloating: floating,
        redirectUrl: redirectUrl,
      },
      "*"
    );
  };
  const handleDateClick = (day, monthIndex, year) => {
    setSelectedDate({ year, month: monthIndex, day });
    // Format the date string correctly, ensuring monthIndex is incremented
    const formattedMonth = monthIndex; // JavaScript months are 0-indexed
    const formattedMonthStr =
      formattedMonth <= 9 ? `0${formattedMonth}` : formattedMonth;
    const formattedDay = day <= 9 ? `0${day}` : day;

    const selectDate = `${year}-${formattedMonthStr}-${formattedDay}`;
    // Filter the opening hours for the selected date
    const indviudualOpeningHours = OpeningHoursWidget?.filter(
      (item) => dayjs(item?.openingDate).format("YYYY-MM-DD") === selectDate
    );
    seSelecteOpeningHours(indviudualOpeningHours);
  };

  const getDayWithSuffix = (day) => {
    if (day > 3 && day < 21) return `${day}th`; // For 11th to 20th
    switch (day % 10) {
      case 1:
        return `${day}st`;
      case 2:
        return `${day}nd`;
      case 3:
        return `${day}rd`;
      default:
        return `${day}th`;
    }
  };

  const formatDate = ({ year, month, day }) => {
    const date = dayjs(`${year}-${month}-${day}`);
    const dayWithSuffix = getDayWithSuffix(day);
    const formattedMonth = date.format("MMMM");
    return `${dayWithSuffix} ${languageWords[`${formattedMonth}`]} ${year}`;
  };

  const renderCalendar = (monthIndex, year) => {
    // Helper function to format date as 'YYYY-MM-DD' for matching
    const formatDateForComparison = (day) => {
      return `${year}-${(monthIndex + 1).toString().padStart(2, "0")}-${day
        .toString()
        .padStart(2, "0")}`;
    };

    // Get the day of the week the first day of the month falls on (adjusted so Monday is the first day)
    let firstDayOfMonth = new Date(year, monthIndex, 1).getDay();
    firstDayOfMonth = firstDayOfMonth === 0 ? 6 : firstDayOfMonth - 1; // Adjust so Monday is 0, Sunday is 6

    // Calculate the number of days in the month
    const totalDaysInMonth = daysInMonth(monthIndex, year);
    return (
      <Box
        sx={{
          width: { md: "100%", sm: "100%" },
          minHeight: "450px",
          border: "1px solid #EBEBEB",
          padding: isMediumOrLargeScreen ? "1.5rem" : "16px",
          borderRadius: "8px",
          marginBottom: 2,
          backgroundColor: theme?.openingHours?.calendarBackgroundColor,
        }}
      >
        <Typography
          variant="h6"
          sx={{
            marginBottom: 1,
            color: theme?.openingHours?.monthTextColor,
            fontSize: "20px",
            fontWeight: "medium",
          }}
        >
          {months[monthIndex]} {year}
        </Typography>

        {/* Days of the Week Row */}
        <Grid container spacing={3}>
          {daysOfWeek?.map((day, index) => (
            <Grid item xs={1.71} md={1.71} key={index}>
              <Typography
                sx={{
                  textAlign: "center",
                  fontWeight: "medium",
                  color: theme?.openingHours?.dayTextColor, // Optional color for day labels
                  fontSize: "16px",
                }}
              >
                {day}
              </Typography>
            </Grid>
          ))}
        </Grid>

        {/* Dates Grid */}
        <Grid container spacing={3} sx={{ marginTop: 1 }}>
          {/* Add empty grid items to align the first day of the month */}
          {Array.from({ length: firstDayOfMonth }).map((_, index) => (
            <Grid item xs={1.71} md={1.71} key={`empty-${index}`} />
          ))}

          {/* Render the actual days of the month */}
          {Array.from({ length: totalDaysInMonth }, (_, day) => {
            const formattedDate = formatDateForComparison(day + 1);
            const isOpeningDate = OpeningHoursWidget?.some(
              (item) =>
                dayjs(item.openingDate).format("YYYY-MM-DD") == formattedDate
            );
            const currentDate = new Date();
            const isPast =
              new Date(year, monthIndex, day + 1) <
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate()
              );
            const dayStyles = isOpeningDate
              ? {
                  border: `1px dashed ${theme?.openingHours?.openDayBorderColor}`,
                  backgroundColor: theme?.openingHours?.openDayBackgroundColor,
                  color: theme?.openingHours?.openDayTextColor,
                  cursor: "pointer",
                
                }
              : {
                  border: `1px dashed ${theme?.openingHours?.closedDateBorderColor}`,
                  backgroundColor:
                    theme?.openingHours?.closedDateBackgroundColor,
                  color: theme?.openingHours?.closedDateTextColor,
                  cursor: "default",
                };

            return (
              <Grid item xs={1.71} md={1.71} key={day}>
                <Box
                  onClick={(event) => {
                    if (!isOpeningDate || isPast) {
                      return;
                    }
                    setAnchorEl(event.currentTarget);
                    handleDateClick(day + 1, monthIndex + 1, year);
                  }} // `day + 1` for 1-based days
                  sx={{
                    width: 30,
                    height: 30,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "auto",
                    ...dayStyles,
                    borderRadius: "4px",
                    "&:hover": {
                      backgroundColor:
                        isOpeningDate && !isPast ? theme?.openingHours?.OpeningsHoursOpenDayBackgroundColorHover : "",
                      borderColor: isOpeningDate && !isPast ? theme?.openingHours?.OpeningsHoursOpenDayBorderColorHover : '',
                      color: isOpeningDate && !isPast ? theme?.openingHours?.OpeningsHoursOpenDayTextColorHover : '',
                    },
                  }}
                >
                  {day + 1}
                </Box>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  PaperProps={{
                    sx: {
                      boxShadow: "none", // Removes any shadow
                      backgroundColor:
                        theme?.openingHours?.popupBackgroundColor, // Removes any background color, if necessary
                    },
                  }}
                >
                  <Box
                    sx={{
                     minHeight:"180px",
                      width: isSmallScreen ? "100%" :"400px",
                      px: 2,
                      py:2,
                      backgroundColor: theme?.openingHours?.OpeningsHoursPopupBackgroundColor,
                      borderRadius: "5px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                        gap: "1rem",
                      }}
                    >
                      <Typography
                        sx={{
                          color: theme?.openingHours?.popupDateTextColor,
                          fontSize: "16px",
                          fontWeight: "600",
                        }}
                      >
                        {formatDate(selectedDate)}
                      </Typography>
                      <IconButton
                        aria-label="close"
                        size="small"
                        sx={{
                          border: `1px solid ${theme?.openingHours?.popupCloseBorderColor}`,
                          borderRadius: "5px",
                          width: 25,
                          height: 25,
                          backgroundColor:
                            theme?.openingHours?.popupCloseBackgroundColor,
                          '&:hover':{
                            borderColor: theme?.openingHours?.OpeningsHoursPopupCloseBorderColorHover,
                            backgroundColor: theme?.openingHours?.OpeningsHoursPopupCloseBackgroundColorHover,

                          }
                        }}
                      >
                        <CloseIcon
                          fontSize="inherit"
                       
                          onClick={handleClose}
                          sx={{
                            color:theme?.openingHours?.popupCloseXColor,
                            '&:hover':{
                              color:theme?.openingHours?.OpeningsHoursPopupCloseXColorHover
                            }
                          }}
                        />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 2,
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            color:
                              theme?.openingHours?.popupOpenFromToTextColor,
                            fontWeight: "medium",
                            fontSize: "14px",
                          }}
                        >
                          {languageWords?.OpenFrom}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.3rem",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ width: "16px", height: "16px" }}>
                            <AccessTimeIcon
                              sx={{ width: "100%", height: "100%" }}
                              color={theme?.openingHours?.popupClockColor}
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: theme?.openingHours?.popupTimeTextColor,
                              fontWeight: "medium",
                              fontSize: "12px",
                              mt: 0.3,
                            }}
                          >
                            {selectedOpeningHours[0]?.openTime?.slice(0, 5)}
                          </Typography>
                        </Box>
                      </Box>
                      <Box>
                        <Typography
                          sx={{
                            color:
                              theme?.openingHours?.popupOpenFromToTextColor,
                            fontWeight: "medium",
                            fontSize: "14px",
                          }}
                        >
                          {languageWords?.OpenTo}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            gap: "0.3rem",
                            alignItems: "center",
                          }}
                        >
                          <Box sx={{ width: "16px", height: "16px" }}>
                            <AccessTimeIcon
                              sx={{ width: "100%", height: "100%" }}
                              color={theme?.openingHours?.popupClockColor}
                            />
                          </Box>
                          <Typography
                            sx={{
                              color: theme?.openingHours?.popupTimeTextColor,
                              fontWeight: "medium",
                              fontSize: "12px",
                              mt: 0.3,
                            }}
                          >
                            {selectedOpeningHours[0]?.closeTime?.slice(0, 5)}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    <Box sx={{display:"flex",justifyContent:'center',alignItems:'center'}}>
                      <Button
                        sx={{
                          backgroundColor:
                            theme?.openingHours?.popupButtonBackgroundColor,
                          borderColor:
                            theme?.openingHours?.popupButtonBorderColor,
                          color: theme?.openingHours?.popupButtonTextColor,
                          mt: 2,
                        }}
                        size="small"
                        onClick={() => {
                          send(
                            "0px",
                            "0px",
                            "",
                            "0px",
                            "0px",
                            "",
                            "fixed",
                            widgetUrl
                          );
                        }}
                      >
                        {languageWords?.BookingResservationBtnText}
                      </Button>
                    </Box>
                  </Box>
                </Popover>
              </Grid>
            );
          })}
        </Grid>
      </Box>
    );
  };

  const getMaxOpeningDate = (openingHoursWidget) => {
    const latestOpeningDate = openingHoursWidget
      ?.map((widget) => dayjs(widget.openingDate))
      .reduce(
        (maxDate, currentDate) =>
          currentDate.isAfter(maxDate) ? currentDate : maxDate,
        dayjs(0)
      ); // Start with the earliest date

    return { month: latestOpeningDate.month(), year: latestOpeningDate.year() }; // month() returns 0-based index
  };
  const { month: maxMonth, year: maxYear } =
    getMaxOpeningDate(OpeningHoursWidget);
  const isForwardButtonDisabled =
    selectedYear > maxYear ||
    (selectedYear === maxYear && selectedMonth >= maxMonth);

  // Array of months to display based on screen size
  const monthsToShow = isSmallScreen
    ? [{ month: selectedMonth, year: selectedYear }]
    : [
        { month: selectedMonth, year: selectedYear },
        {
          month: (selectedMonth + 1) % 12, // Move to the next month
          year: selectedMonth === 11 ? selectedYear + 1 : selectedYear, // Handle year transition when moving from December
        },
        {
          month: (selectedMonth + 2) % 12, // Move to the month after next
          year: selectedMonth >= 10 ? selectedYear + 1 : selectedYear, // Handle year transition for November and December
        },
      ];
  const fetchOpeningHours = async () => {
    setLoadingOpeningHours(true);
    try {
      const response = await GetOpeningHours(key, defaultLanguage);
      const result = response?.basicLanguages?.reduce((acc, item) => {
        const tag = item.tag.replace(/{|}/g, "").replace(/\s+/g, "");
        const data = item.widgetTranslateLanguages[0]?.name;
        acc[tag] = data; // Assign data to the key acc[tag]
        return acc;
      }, {});
      dispatch(SetUpateOpeningHours(response?.openingHours));
      dispatch(updateWidgetUrl(response?.widgetUrl));
      dispatch(setLanguageWords(result));
      setLoadingOpeningHours(false);
    } catch (error) {
      console.error("Error fetching opening hours:", error.message);
      setLoadingOpeningHours(false);
    }
  };
  const fetchLanguageDetails = async () => {
    setLangLoading(true);
    try {
      const response = await getLanguageDetails(key);
      if (response?.status != 200) {
        console.error("Error fetching language details widget:", response);
        return;
      }
      dispatch(updateLanguageData(response?.data));
      const langParam = queryParams.get("lang");
      if (response?.data) {
        const defaultLang =
          !defaultLanguage && !langParam
            ? response?.data?.find((item) => item?.defaultLanguage)?.langcode
            : langParam
            ? langParam
            : defaultLanguage;

        dispatch(updateDefaultLanguage(defaultLang));
        dispatch(setLanguageLoaded(true)); // Set the flag to true
        dispatch(setLanguageID(defaultLang?.idlanguage));
      }
      setLangLoading(false);
    } catch (error) {
      console.error("Error fetching language details widget:", error?.message);
      setLangLoading(false);
    }
  };

  const fetchWidgetSettingsDetails = async (lang) => {
    try {
      setWidgetLoading(true);
      const response = await FetchWidgetConfigDetails(key, widgetSetupId, lang);
      if (response?.status != 200 || !response?.data) {
        throw new Error(
          "Failed to fetch widget settings details. Please try again later."
        );
      }
      dispatch(updateWidgetConfig(response?.data));
      setWidgetLoading(false);
    } catch (error) {
      console.error("Error fetching widget settings details:", error);
      setWidgetLoading(false);
    }
  };

  useEffect(() => {
    fetchWidgetSettingsDetails(defaultLanguage);
  }, []);

  useEffect(() => {
    if (defaultLanguage) fetchOpeningHours();
  }, [defaultLanguage]);
  useEffect(() => {
    fetchLanguageDetails();
  }, []);

  if (loadingOpeningHours || langLoading) {
    return (
      <Backdrop
        sx={(theme) => ({ color: "#fff", zIndex: theme.zIndex.drawer + 1 })}
        open={loadingOpeningHours}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }

  return (
    <Box
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
    >
      <Paper
        elevation={1}
        sx={{
          backgroundColor: theme?.openingHours?.backgroundColor,
          width: "100%",
          minHeight: "100vh",
          height: "auto",
        }}
      >
        <Box sx={{ textAlign: "center", padding: "16px" }}>
          {/* Heading */}
          <Typography
            sx={{
              marginBottom: 2,
              fontSize: "24px",
              fontWeight: "medium",
              color: theme?.openingHours?.headerTextColor,
            }}
          >
            {languageWords?.OpeninghrsCalender}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <LanguageMenuItem />
          </Box>

          {/* Year & Month Navigation */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              marginBottom: 2,
            }}
          >
            <IconButton
              aria-label="back"
              onClick={handlePreviousMonth}
              disabled={
                selectedYear < dayjs().year() ||
                (selectedYear === dayjs().year() &&
                  selectedMonth <= dayjs().month())
              }
            >
              <ArrowBack color={theme?.openingHours?.arrowLeftColor} />
            </IconButton>
            <Typography
              variant="h6"
              sx={{
                margin: "0 16px",
                color: theme?.openingHours?.headerTextColor,
              }}
            >
              {selectedYear}
            </Typography>

            <IconButton
              aria-label="forward"
              onClick={handleNextMonth}
              disabled={isForwardButtonDisabled}
            >
              <ArrowForward
                color={
                  isForwardButtonDisabled
                    ? "disabled"
                    : theme?.openingHours?.arrowRightColor
                }
              />
            </IconButton>
          </Box>

          {/* Filter Buttons */}
          {/* <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: 2,
              gap: "1rem",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                color: "#969BA0",
                backgroundColor: "#FDFDFD",
                borderRadius: "5.5px",
                textTransform: "none",
                border: "1px dashed #969BA0",
              }}
             // onClick={() => handleFilterChange("Closed")}
            >
              Close
            </Button>
            <Button
              variant="outlined"
              sx={{
                color: "#F37120",
                backgroundColor: "#FFF8F6",
                borderRadius: "5.5px",
                textTransform: "none",
                border: "1px dashed #F37120",
              }}
              //onClick={() => handleFilterChange("Availability")}
            >
              Available
            </Button>
          </Box> */}

          {/* Calendar View */}
          <Grid container spacing={2} mt={2}>
          {monthsToShow.map(({ month, year }, idx) => (
              <Grid item xs={12} sm={6} md={4} key={idx}>
                {renderCalendar(month, year)}
              </Grid>
            ))}
          </Grid>
        </Box>
      </Paper>
    </Box>
  );
};

export default OpeningHoursCalendar;
