import * as React from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import {
  Badge,
  Box,
  CircularProgress,
  Grid,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { useTheme } from "@emotion/react";
import burgerImg from "../../assets/menuBurgerImg.png";
import MenuListCardsComponent from "./MenuListCardsComponent";
import DeleteIcon from "@mui/icons-material/Delete";
import TextInput from "../../components/Input/TextInput";
import { useDispatch, useSelector } from "react-redux";
import { ArrowBackOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { CreateWebShopOrder } from "../../api/Webshop.service";
import dayjs from "dayjs";
import { updateOrderNumberDetails } from "../../store/slice/Webshop.slice";
import { format_Number } from "../../utils/utils";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const sampleData = [
  { product: "Beef Steak burger", price: 40, quantity: 2, total: 40 },
  { product: "Zinger Burger", price: 80, quantity: 1, total: 80 },
  { product: "Cheese Burger", price: 15, quantity: 3, total: 15 },
  { product: "Veg Burger", price: 120, quantity: 4, total: 120 },
  { product: "Chicken cheese burger", price: 25, quantity: 5, total: 25 },
];
export default function Completebill({ open, handleClose, back }) {
  const theme = useTheme();
  const dispatch=useDispatch()
  const currentDate=dayjs()
  const navigate = useNavigate();
  const {
    shopType,
    webShopProductInfo,
    itemCount,
    addToCartProducts,
    supplementsInfo,
    webshopSettings,
    deliveryType,
    guestDetails
  } = useSelector((store) => store?.Webshop);
  const{key}=useSelector((store)=>store?.widgetSettings)
  // const{key}=useSelector((store)=>store?.widgetSettings)
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("lg"));
  const [loader, setLoader] = React.useState(false);
  const [totalVat,setTotalVat]=React.useState(0);
  const [totalPrice,setTotalPrice]=React.useState(0)

const calculateTotalPrice = React.useCallback(
  (onlyVat = false) => {
    let vatAmount;
    const totalProductsPrice = addToCartProducts?.reduce((acc, product) => {
      // Find supplements info for the product
      const supplementInfo = supplementsInfo?.find(
        (item) => item?.idproduct === product.idproduct
      );

      // Calculate the total price of supplements for this product
      const supplementTotal =
        supplementInfo?.supplements?.reduce(
          (suppAcc, supplement) => suppAcc + (supplement?.price || 0),
          0
        ) || 0;

      // Calculate the total price for the product including supplements
      const productTotal =
        product.price * (itemCount[product.idproduct] || 0) + supplementTotal;

      // Add the VAT for this product
      vatAmount =
        onlyVat || webshopSettings?.pricesIncludingVAT
          ? CalculateVat(productTotal, product?.vat || 0)
          : 0;
      return acc + productTotal + vatAmount;
    }, 0);

    if (onlyVat) {
      return vatAmount;
    }
    return totalProductsPrice;
  },
  [addToCartProducts, supplementsInfo, itemCount, webshopSettings]
);


  function CalculateVat(price, vatPersentage) {
    const vat = (price / (vatPersentage + 100)) * vatPersentage;
    return Math.round(vat * 100) / 100
  }

  const ProductPrice = (id, productPrice, vat) => {
    let vatAmount;

    // Find supplements info for the product
    const supplementInfo = supplementsInfo?.find(
      (item) => item?.idproduct == id
    );

    // Calculate the total price of supplements for this product
    const supplementTotal =
      supplementInfo?.supplements?.reduce(
        (suppAcc, supplement) => suppAcc + (supplement?.price || 0),
        0
      ) || 0;
    // Calculate the total price for the product including supplements
    const productTotal = productPrice * (itemCount[id] || 0) + supplementTotal;

    // Add the VAT for this product
    vatAmount = webshopSettings?.pricesIncludingVAT
      ? CalculateVat(productTotal, vat || 0)
      : 0;
    return productTotal + vatAmount;
  };

  

 
  const createOrderDetail = async () => {
    const extractSupplementIds = (supplements, detailsKey) => {
      return supplements
        ?.filter((supItem) => supItem?.detailsKey === detailsKey)
        ?.map((supItem) => supItem?.idwebshopsupplementitem) || [];
    };
  
    const productItems = addToCartProducts?.map((item) => {
      const specialPrice = item?.specialPriceFields?.find((price) =>
        currentDate.isBetween(
          dayjs(price.specialpricefrom, "DD-MM-YYYY"),
          dayjs(price.specialpriceto, "DD-MM-YYYY"),
          null,
          "[]"
        )
      );
  
      const supplementsForProduct = supplementsInfo?.find(
        (supplement) => supplement?.idproduct === item?.idproduct
      )?.supplements || [];
  
      // Dynamically assign supplements for all detailsKey
      const supplementDetailsKeys = [
        "supplementProductsDetails1",
        "supplementProductsDetails2",
        "supplementProductsDetails3",
      ];
  
      // Ensure all keys are processed and added correctly
      const idSupplement1 = extractSupplementIds(
        supplementsForProduct,
        "supplementProductsDetails1"
      );
      const idSupplement2 = extractSupplementIds(
        supplementsForProduct,
        "supplementProductsDetails2"
      );
      const idSupplement3 = extractSupplementIds(
        supplementsForProduct,
        "supplementProductsDetails3"
      );
  
      return {
        webshopOrderItemId: 0,
        productId: item.idproduct,
        quantity: itemCount[item.idproduct],
        price: item.price,
        isSpecialPrice: !!specialPrice,
        isRelatedProductPrice: item?.relatedproductprice ? true : false,
        idSupplement1: `[${idSupplement1.join(" ")}]`,
        idSupplement2: `[${idSupplement2.join(" ")}]`,
        idSupplement3: `[${idSupplement3.join(" ")}]`,
      };
    });
  
    const payload = {
      webshopOrder: {
        deliveryCost: webshopSettings?.deliveryStandardCost,
        totalVAT: totalVat,
        totalCost: webshopSettings?.pricesIncludingVAT
          ? totalPrice - totalVat
          : totalPrice,
        totalIncludingVAT: webshopSettings?.pricesIncludingVAT
          ? totalPrice
          : totalPrice + totalVat,
        isPaid: true,
        isDelivery: deliveryType === "delivery",
        idguest: guestDetails?.value?.idguest || 0,
        paymentMethod: 2,
      },
      webshopOrderItemsVM: productItems,
      key,
    };
  
    setLoader(true);
    try {
      const response = await CreateWebShopOrder(payload);
      if(response?.status == 200){
        dispatch(updateOrderNumberDetails(response?.data))
      }
      setLoader(false);
      handleClose();
      navigate(`/webshopSuccess${response?.status !== 200 ? "" : "?status=success"}`);
    } catch (error) {
      setLoader(false);
      console.error(error?.message);
    }
  };
  
  
  
 
  React.useEffect(() => {
    const total = calculateTotalPrice();
    setTotalPrice(total);
    const vat = calculateTotalPrice(true);
    setTotalVat(vat);
  }, [calculateTotalPrice]);
  return (
    <React.Fragment>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        PaperProps={{
          sx: {
            width: isSmallScreen ? "100%" :  "70%", // Set to 90% of the screen width
            maxWidth: "none", // Prevents default Material-UI maxWidth constraints
            height: "auto", // Allows height to adjust dynamically
            minHeight: "500px",
          },
        }}
      >
        <Box>
          <IconButton
            aria-label="close"
            onClick={back}
            sx={{
              position: "absolute",
              left: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <ArrowBackOutlined sx={{ color: "#F37120" }} />
          </IconButton>
          <DialogTitle
            id="recommendation-dialog-title"
            sx={{
              color: "#00000",
              fontWeight: "bold",
              fontSize: "1.2rem",
              mb: 3,
              textAlign: "center",
            }}
          >
            Checkout
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <DialogContent sx={{ mt: 2 }}>
            <Box>
                  <Grid2 container spacing={2}>
            <Grid2 size={{ xs: 5 }}>
              <Typography sx={{ fontWeight: "600", fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px" }}>
                Product
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px"}}>
                Price
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 3 }}>
              <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px"}}>
               {isSmallScreen ? 'Qty' : 'Quantity'} 
              </Typography>
            </Grid2>
            <Grid2 size={{ xs: 2 }}>
              <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px" }}>
                Total
              </Typography>
            </Grid2>
          </Grid2>
          {/* Sample Data Rows */}
          {addToCartProducts.map((item, index) => (
            <Grid2
              container
              key={index}
              sx={{ marginBottom: 2, mt: 2 }}
              spacing={2}
            >
              <Grid2 size={{ xs: 5 }}>
                <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px" }}>
                  {item.displayname}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 2 }}>
                <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px" }}>
                  &euro;{format_Number(item.price)}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 3 }}>
                <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px" }}>
                  {itemCount[item?.idproduct]}
                </Typography>
              </Grid2>
              <Grid2 size={{ xs: 2 }}>
                <Typography sx={{ fontWeight: "600",fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px" }}>
                  &euro;{format_Number(ProductPrice(item?.idproduct, item?.price, item?.vat))}
                </Typography>
              </Grid2>
            </Grid2>
          ))}
            </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end", // Align items to the right end
              marginTop: "1rem",
              mr: 2,
              mt: 5,
            }}
          >
            {totalPrice <
              webshopSettings?.freeDeliveryAfterAmount && (
              <Box
                sx={{
                  display: "flex",
                  gap: "3rem",
                  mb: 2,
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px", fontWeight: 500 }}>
                  Delivery cost
                </Typography>
                <Typography sx={{ fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px", fontWeight: 500 }}>
                  {format_Number(webshopSettings?.deliveryStandardCost)}
                </Typography>
              </Box>
            )}
            <Box
              sx={{ display: "flex", gap: "3rem", mb: 2, alignItems: "center" }}
            >
              <Typography sx={{fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px", fontWeight: 500 }}>
                Total
              </Typography>
              <Typography sx={{ fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px", fontWeight: 500 }}>
                €
                {totalPrice <
                webshopSettings?.freeDeliveryAfterAmount
                  ? format_Number(totalPrice +
                    webshopSettings?.deliveryStandardCost)
                  : format_Number(totalPrice)}
              </Typography>
            </Box>
            <Box
              sx={{ display: "flex", gap: "3rem", mb: 2, alignItems: "center" }}
            >
              <Typography sx={{ fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px", fontWeight: 500 }}>
                VAT
              </Typography>
              <Typography sx={{ fontSize:isSmallScreen ? '12px': isMediumScreen ? "14px" : "20px", fontWeight: 500 }}>
                €{format_Number(totalVat)}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                gap: "3rem",
                alignItems: "center",
                borderTop: "1px solid black",
                pt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "bold" }}>
                Total Including VAT
              </Typography>
              <Typography sx={{ fontWeight: "bold" }}>
                {" "}
                €
                {totalPrice <
                webshopSettings?.freeDeliveryAfterAmount
                  ? format_Number(totalPrice +
                    webshopSettings?.deliveryStandardCost)
                  : format_Number(totalPrice)}
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <Button
              variant="contained"
              fullWidth
              size={isSmallScreen ? 'small' : 'medium'}
              sx={{
                backgroundColor: "#F37120",
                color: "#fff",
                width: "404px",
                p: 2,
              }}
              onClick={() => {
            createOrderDetail()
              }}
              disabled={loader}
            >{
              loader ?
              <Box sx={{display:"flex",justifyContent:'center',alignItems:'center'}}>
                  <CircularProgress /> 

              </Box>
            :
              <>
                   Pay Now &euro;{" "}
              {totalPrice < webshopSettings?.freeDeliveryAfterAmount
                ? format_Number(totalPrice + webshopSettings?.deliveryStandardCost)
                : format_Number(totalPrice)}
              </>
            }
              
         
            </Button>
          </Box>
        </DialogContent>
        <DialogActions></DialogActions>
      </BootstrapDialog>
    </React.Fragment>

  );
}
