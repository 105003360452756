import { Backdrop, Box, CircularProgress } from '@mui/material'
import React, { useEffect } from 'react'
import Menubar from './Menubar'
import { useDispatch, useSelector } from 'react-redux'
import { FetchWebShopProductList, FetchWebshopProductsInfo } from '../../api/Webshop.service'
import { setIsProductLoading, setWebshopProductData, setWebshopProductInfo } from '../../store/slice/Webshop.slice'
import KioskMenuItemListComponent from './KisokMenuItemListComponent'
import InactivityPopup from './InActivityPopup'

const KioskMenuComponent = () => {
  const dispatch=useDispatch()
    const {webShopMenuPosition,defaultLanguage,selectedMenuItem,isProductLoading,webShopProductList}=useSelector((store)=>store?.Webshop)
    const {key}=useSelector((store)=>store?.widgetSettings)
    const handleCancelAction = () => {
      console.log("User canceled the operation due to inactivity.");
      // Add your logic to cancel the operation here
    };

    const getProductList = async (selectedMenuItem) => {
      dispatch(setIsProductLoading(true));
      dispatch(
        setWebshopProductData(
           []
         )
      );
      try {
        const response = await FetchWebShopProductList(key, defaultLanguage, selectedMenuItem?.idCategory);
    
        if (response?.status !== 200) {
          console.error("Error fetching product list:", response.status);
        } else {
          // Dispatch the combined product data
          dispatch(
            setWebshopProductData(
               response?.data, // Original product data
             )
          );
        }
      } catch (error) {
        console.error("Error fetching product list:", error);
      } finally {
        // Ensure loading state is reset
        dispatch(setIsProductLoading(false));
      }
    };
    const getProductsInfo = async (key, defaultLanguage) => {
      try {
        const productInfo = await Promise.all(
          webShopProductList.map(async (item) => {
            const productDataDetails = await FetchWebshopProductsInfo(key, defaultLanguage, item?.idproduct);
            return productDataDetails?.data; // Extract the necessary data
          })
        );
    
        if (!productInfo) {
          console.error("Error fetching product list:", productInfo);
        } else {
          // Dispatch the combined product data
          dispatch(
            setWebshopProductInfo(
              productInfo
             )
          );
        }
      } catch (error) {
        console.error("Error fetching product list:", error);
      } 
    };




  return (
    <>
      <InactivityPopup onConfirmCancel={handleCancelAction} />
    <Box sx={{display:webShopMenuPosition == 'vertical' ? "flex" : 'block',backgroundColor:"#EBEBEB",height:"auto",minHeight:"100vh"}}>
      <Menubar  getProductList={getProductList} getProductsInfo={getProductsInfo} />
      { isProductLoading && webShopMenuPosition ?
        <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
        open={isProductLoading}
        onClick={()=>setIsProductLoading(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop> 
      :webShopMenuPosition && <KioskMenuItemListComponent />}
    </Box>
    </>
  )
}

export default KioskMenuComponent
