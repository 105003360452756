import React, { useEffect, useRef, useState } from "react";
import { Modal, Button, Typography, Box } from "@mui/material";
import { resetKiosk } from "../../store/slice/Webshop.slice";
import { useDispatch } from "react-redux";

const InactivityPopup = ({ onConfirmCancel }) => {
  const dispatch = useDispatch();
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [countdown, setCountdown] = useState(10);
  const timerRef = useRef(null); // Timer for inactivity
  const countdownRef = useRef(null); // Timer for countdown display

  const resetInactivityTimer = () => {
    if (timerRef.current) clearTimeout(timerRef.current);
    timerRef.current = setTimeout(() => {
      setIsPopupVisible(true);
      startCountdown();
    }, 30000); // 10 seconds
  };

  const startCountdown = () => {
    setCountdown(10); // Reset countdown
    if (countdownRef.current) clearInterval(countdownRef.current);
    countdownRef.current = setInterval(() => {
      setCountdown((prev) => {
        if (prev <= 1) {
          clearInterval(countdownRef.current);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const handleUserActivity = () => {
    resetInactivityTimer(); // Reset the inactivity timer
    if (isPopupVisible) {
      setIsPopupVisible(false); // Hide popup if visible
      clearInterval(countdownRef.current); // Stop countdown
    }
  };

  const handleCancel = () => {
    dispatch(resetKiosk());
    setIsPopupVisible(false);
    resetInactivityTimer(); // Continue working
    

  };

  const handleConfirm = () => {
    setIsPopupVisible(false);
    if (onConfirmCancel) onConfirmCancel(); // Notify parent about cancellation
  };

  useEffect(() => {
    // Add event listeners for user activity
  //  window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);

    resetInactivityTimer(); // Initialize the inactivity timer

    return () => {
      // Cleanup on unmount
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      if (timerRef.current) clearTimeout(timerRef.current);
      if (countdownRef.current) clearInterval(countdownRef.current);
    };
  }, []);

  return (
    <Modal open={isPopupVisible} onClose={handleCancel}>
      <Box
        sx={{
          padding: "20px",
          textAlign: "center",
          background: "white",
          margin: "15% auto",
          maxWidth: "400px",
          borderRadius: "10px",
          
        }}
      >
        <Typography
          variant="h2"
          sx={{
            fontWeight: "bold",
            fontSize: "48px",
            marginBottom: "10px",
            color: countdown > 5 ? "black" : "orange",
          }}
        >
          {countdown}s
        </Typography>
        <Typography variant="h5" sx={{ marginBottom: "10px" }}>
          Are you still here?
        </Typography>
        <Typography
          variant="body2"
          sx={{ marginBottom: "20px", color: "gray" }}
        >
          Lorem ipsum dolor sit amet consectetur. Molestie eu massa nunc
          consectetur eget erat nec.
        </Typography>
        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancel}
            sx={{
              backgroundColor: "#C4C4C4",
              "&:hover": { backgroundColor: "#A9A9A9" },
            }}
          >
            Cancel Order
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            sx={{
              backgroundColor: "#FF6F00",
              "&:hover": { backgroundColor: "#FF8C00" },
            }}
          >
            Yes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default InactivityPopup;

