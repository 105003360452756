import logo from './logo.svg';
import './App.css';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { Navigate, Outlet, createBrowserRouter } from'react-router-dom';
import WidgetDynamicLoading from './widgets/WidgetDynamicLoading';
import BookNowForm from './widgets/horecaWidget/BooknowForm';
import { Provider } from 'react-redux'
import { store,persistor } from './store/Store';
import PaymentSuccess from './widgets/horecaWidget/PaymentSuccess';
import Paymentfailure from './widgets/horecaWidget/Paymentfailure';
import PaymentPending from './widgets/horecaWidget/PaymentPending';
import { Suspense, useEffect } from 'react';
import { useTranslation} from 'react-i18next';
import SuccessScreen from './widgets/feedbackWidget/SuccessScreen';
import CancelReservation from './widgets/horecaWidget/CancelReservation';
import OpeningHoursCalendar from './widgets/openingHours/OpeningHours';
import ReservationCancelResponse from './widgets/horecaWidget/ReservationCancelResponse';
import BookNowFormTicket from './widgets/ticketingWidget/BookNowFormTicket';
import BookNowEvent from './widgets/eventWidget/BookNowEvent';
import PaymentSuccessEvent from './widgets/eventWidget/PaymentSuccessEvent';
import BookNowRental from './widgets/rentalWidget/BookNowRental';
import BookNowBowling from './widgets/bowlingWidget/BookNowBowling';
import BookNowActivities from './widgets/ActivitiesWidget/BookNowActivities';
import PaymentSuccessActivity from './widgets/ActivitiesWidget/PaymentSuccessActivity';
import ThemeProviderWrapper from './theme';
import BookLessons from './widgets/lessonsWidget/BookLessons';
import SuccessModalLesson from './widgets/lessonsWidget/SuccessPaymentLesson';
import BookNowSalon from './widgets/salonWidget/BooknowSalon';
import ConditionalRedirect from './widgets/horecaWidget/ConndtionalRedirect';
import { PersistGate } from 'redux-persist/integration/react';
import Menubar from './widgets/WebshopWidget/Menubar';
import MenuComponent from './widgets/WebshopWidget/MenuComponent';
import MenuItemListComponent from './widgets/WebshopWidget/MenuItemListComponent';
import AddToCart from './widgets/WebshopWidget/AddToCart';
import WebshopSuccess from './widgets/WebshopWidget/WebshopSuccess';
import BookNow from './widgets/hotelWidget/BookNow';
import HotelBookNowForm from './widgets/hotelWidget/HotelBookNowForm';
import KioskMenuComponent from './widgets/Kiosk/KioskMenuComponent';
import KioskMenuItemListComponent from './widgets/Kiosk/KisokMenuItemListComponent';




const hasValidQueryParams = (search) => {
  const params = new URLSearchParams(search);
  const widgetSetupId = params.get("widgetsetupid");
  const key = params.get("key");
  const resType = params.get("resType");
  return widgetSetupId && key && resType; // Check if all required parameters exist
};

const WidgetWrapper = () => {
  // Here we can extract the search from window location
  const search = window.location.search;

  return hasValidQueryParams(search) ? <WidgetDynamicLoading /> : <Navigate to="/" />;
};
export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Suspense fallback={<div>Loading...</div>}>
        <Provider store={store}>
        <ThemeProviderWrapper> {/* Use the ThemeProviderWrapper here */}
          <App />
          </ThemeProviderWrapper>
        </Provider>
      </Suspense>
    ),

    children: [
      {
        path: "/",
        element: <ConditionalRedirect />, // Redirect to base path
      },
      {
        path: "widget",
        element: <WidgetWrapper />, // Use the wrapper component to check for query params
        children: [
    
        
        ],
      },
      {
        path: "/successFeedback",
        element: <SuccessScreen />,
      },
      {
        path:`/`,
        element: <PaymentSuccessActivity />,
      },
      {
        path: "/ReservationCancelSuccess",
        element: <ReservationCancelResponse />,
      },
      {
        path: "/cancelReservation",
        element: <CancelReservation />,
      },
      {
        path: "/openingHours/:key",
        element: <OpeningHoursCalendar />,
      },
      {
        path: "/bookNow",
        element: <BookNowForm />,
      },
      {
        path: "/bookNowHotel",
        element: <BookNow />,
      },
      {
        path: "/bookNowFormHotel",
        element: <HotelBookNowForm />,
      },
      {
        path: "bookNowTickets",
        element: <BookNowFormTicket />,
      },
      {
        path: "bookNowRental",
        element: <BookNowRental />,
      },
      {
        path: "bookNowBowling",
        element: <BookNowBowling />,
      },
      {
        path: "bookNowActivity",
        element: <BookNowActivities />,
      },
      {
        path: "bookNowEvents",
        element: <BookNowEvent />,
      },
      {
        path: "paymentStatus",
        element: <PaymentSuccess />,
      },
      {
        path: "successLesson",
        element: <SuccessModalLesson />,
      },
      {
        path: "bookLessons",
        element: <BookLessons />,
      },
      {
        path:'bookSalon',
        element: <BookNowSalon />,
      },
   
      {
        path: "PaymentSuccess",
        element: <PaymentSuccessEvent />,
      },
      {
        path: "paymentStatusfail",
        element: <Paymentfailure />,
      },
      {
        path: "paymentpending",
        element: <PaymentPending />,
      },
      {
        path:'webshopMenuItem',
        element:<MenuComponent />
      },
      {
        path:'kisoMenuItem',
        element:<KioskMenuComponent />
      },
      {
        path: "webshopMenu",
        element:<MenuItemListComponent />
      },
      {
        path: "kioskwebshopMenu",
        element:<KioskMenuItemListComponent />
      },
      {
        path: "addtocart",
        element:<AddToCart />
      },
      {
        path:'webshopSuccess',
        element:<WebshopSuccess />
      }
    ],
  },
]);
function App() {

  const { t, i18n } = useTranslation();
  
  return (
    <>
    <CssBaseline />
    <Outlet />
  </>
  );
}

export default App;
