export const getWidgetPositionStyle = (position, btn) => {
    switch (position) {
      case "Top-Left":
        return { top: "0", left: "0", transform: "translate(0, 0)" };
      case "Top-Middle":
        return { top: "0", left: "50%", transform: "translateX(-50%)" };
      case "Top-Right":
        return { top: "0", right: "0", transform: "translate(0, 0)" };
      case "Middle-Left":
        return { top: "50%", left: "0", transform: "translateY(-50%)" };
      case "Middle-Center":
        return { top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
      case "Middle-Right":
        return { top: "50%", right: "0", transform: "translateY(-50%)" };
      case "Bottom-Left":
        return { bottom: "0", left: "0", transform: "translate(0, 0)" };
      case "Bottom-Middle":
        return { bottom: "0", left: "50%", transform: "translateX(-50%)" };
      case "Bottom-Right":
        return { bottom: "0", right: "0", transform: "translate(0, 0)" };
      default:
        // Center by default if position not recognized
        if (btn) {
          return { bottom: "0", right: "0", transform: "translate(0, 0)" };
        } else {
          return {
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          };
        }
    }
  };
export const IMG_BASE_URL=process.env.REACT_APP_API_LOCAL_IMG_URL;
export const format_Number = (num) => {
  let formattedPrice = (parseFloat(num)).toLocaleString('en-GB', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  return formattedPrice.replace('.', ',');
}
