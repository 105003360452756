
import { createSlice } from '@reduxjs/toolkit'
const initialState = {
webshopSettings:{},
webshopLoading:false,
 webShopCategoryList:[],
 webShopProductList:[],
 webShopProductInfo:[],
 languageData:[],
 defaultLanguage:'',
 deliveryType:'',
 shopType:'',
 widgetUrl:'',
 webShopMenuPosition:'',
 selectedMenuItem:'',
 isProductLoading:false,
 itemCount:{},
 totalProductsCount:0,
 supplementsInfo:[],
 selectedProduct:{},
 addToCartProducts:[],
 selectedDeleteItem:{},
 selectedProductTotalPrice:0,
 guestDetails:null,
 orderNumberDetails:{},
 openCart : false,
 viewCartCount:0,
 addToCartOpen:false,
 paymentMethodOpen:false
}
export const WebshopSlice = createSlice({
  name: 'Webshop',
  initialState,
  reducers: {
    setWebshopCategoryData: (state, action) => {
      state.webShopCategoryList = action.payload;
    },
    setLanguageData: (state, action) => {
      state.languageData = action.payload;
    },
    updateDefaultLanguage: (state, action) => {
        state.defaultLanguage = action.payload;
    },
    updateDeliveryType: (state, action) => {
        state.deliveryType = action.payload;
      },
      updateShopType: (state, action) => {
        state.shopType = action.payload
      },
      updateWidgetUrl: (state, action) => {
        state.widgetUrl = action.payload;
      },
      updateWebShopMenuPosition: (state, action) => {
        state.webShopMenuPosition = action.payload;
      },
      updateSelectedMenuItem: (state, action) => {
        state.selectedMenuItem = action.payload;
      },
      setWebshopProductData: (state, action) => {
        state.webShopProductList = action.payload;
      },
      setIsProductLoading: (state, action) => {
        state.isProductLoading = action.payload;
      },
      setWebshopProductInfo: (state, action) => {
        state.webShopProductInfo = action.payload;
      },
      setItemCount: (state, action) => {
        state.itemCount =  action.payload;  
      },
      setSupplementInfo: (state, action) => {
        state.supplementsInfo =action.payload;
      },
      setSelectedProduct: (state, action) => {
        state.selectedProduct =action?.payload;
      },
      setWebshopSettings: (state, action) => {
        state.webshopSettings = action.payload;
      },
      setWebshopLoading: (state, action) => {
        state.webshopLoading = action.payload;
      },
      setTotalProductCount: (state, action) => {
        state.totalProductsCount = action.payload;
      },
      UpdateAddToCartProducts: (state, action) => {
        state.addToCartProducts = [...state.addToCartProducts, action.payload];
      },
      deleteAddToCartProducts: (state, action) => {
        console.log("action",action.payload);
        state.addToCartProducts = state.addToCartProducts.filter((product) => product.idproduct!== action.payload);
      },
      removeAddToCartProducts:(state, action) => {
        state.addToCartProducts = []

      },
       // Add more reducer functions as needed...
       setSelectedDeleteItem:(state, action) => {
        state.selectedDeleteItem = action.payload;
       },
       updateSelectedProductTotalPrice:(state, action) => {
        state.selectedProductTotalPrice = action.payload;
       },
       updateGuestDetails:(state, action) => {
        state.guestDetails = action.payload;
       },
       updateOrderNumberDetails:(state, action) => {
        state.orderNumberDetails = action.payload;

       },
       setOpenCart:(state, action) => {
        state.openCart = action.payload;
       },
       setViewCartCount:(state, action) => {
        state.viewCartCount = action.payload;
       },
       resetKiosk:(state)=>{
        // state.selectedMenuItem =initialState.selectedMenuItem
        state.addToCartProducts = initialState.addToCartProducts
        state.selectedProduct = initialState.selectedProduct
        state.itemCount=initialState.itemCount
        state.totalProductsCount = initialState.totalProductsCount 
        state.addToCartOpen=initialState.addToCartOpen
        state.paymentMethodOpen=initialState.paymentMethodOpen
        state.openCart=initialState.openCart

       },
       setAddToCartOpen:(state, action) => {
        state.addToCartOpen = action.payload;
       },
       setPaymentMethodOpen:(state, action) => {
        state.paymentMethodOpen = action.payload;
       }
  },
})

// Action creators are generated for each case reducer function
export const {
setWebshopCategoryData,
  setLanguageData,
  updateDefaultLanguage,
  updateDeliveryType,
  updateShopType,
  updateWidgetUrl,
  updateWebShopMenuPosition,
  updateSelectedMenuItem,
  setWebshopProductData,
  setIsProductLoading,
  setWebshopProductInfo,
  setItemCount,
  setSupplementInfo,
  setSelectedProduct,
  setWebshopSettings,
  setWebshopLoading,
  setTotalProductCount,
  UpdateAddToCartProducts,
  removeAddToCartProducts,
  deleteAddToCartProducts,
  setSelectedDeleteItem,
  updateSelectedProductTotalPrice,
  updateGuestDetails,
  updateOrderNumberDetails,
  setOpenCart,
  setViewCartCount,
  setAddToCartOpen,
  resetKiosk,
  setPaymentMethodOpen
  // Add more action creators as needed...
} = WebshopSlice.actions

export default WebshopSlice.reducer;
