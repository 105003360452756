import {
  Backdrop,
  Badge,
  Box,
  Button,
  CircularProgress,
  Grid2,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import LanguageMenuItem from "../../components/SelectComponent/LanguageMenuItem";
import SearchIcon from "@mui/icons-material/Search";
import { useTheme } from "@emotion/react";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MenuListCardsComponent from "./MenuListCardsComponent";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import footerImg from "../../assets/footerLogo.png";
import { updateDefaultLanguage,setLanguageData, setWebshopLoading, setOpenCart, setWebshopProductData, setIsProductLoading, } from "../../store/slice/Webshop.slice";
import { useNavigate } from "react-router-dom";
const MenuItemListComponent = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate=useNavigate()
  const {webShopMenuPosition,shopType,selectedMenuItem,webShopProductList,webShopProductInfo,itemCounts,supplementsInfo,languageData,defaultLanguage,webshopLoading,addToCartProducts,totalProductsCount,isProductLoading}=useSelector((store)=>store?.Webshop);
  const [tempList,setTempList]=useState(webShopProductList)
  const [searchItem,setSearchItem]=useState('')

   // Handle search query
   const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchItem(searchTerm);

    // Filter data locally
    if (searchTerm) {
      const filtered = webShopProductList?.filter((item) =>
        item?.displayname?.toLowerCase().includes(searchTerm?.toLowerCase())
      );
      setTempList(filtered);
    } else {
      setTempList(webShopProductList); // Reset to full data if query is empty
    }
  };

  useEffect(()=>{
    setTempList(webShopProductList)
  },[webShopProductList])

  if(isProductLoading) return   <Backdrop
  sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
  open={isProductLoading}
  onClick={()=>dispatch(setIsProductLoading(false))}
>
  <CircularProgress color="inherit" />
</Backdrop>

  return (
    <Box sx={{ width: "100%",px:2,minHeight:"100vh",height:"auto",backgroundColor:"lightgrey" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          pt: 2,
        }}
      >
         <Box>
          {isSmallScreen ? 
          (
            <Box sx={{display:"flex",alignItems:'center',gap:"0.6rem"}}>
           { !webShopMenuPosition && <ArrowBackIcon />}
            <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "600", color: "#00000" }}
            >
             {selectedMenuItem?.name}
            </Typography>
            </Box>
          ) :!webShopMenuPosition ? <ArrowBackIcon sx={{cursor:"pointer"}}  onClick={()=>navigate(-1)}/> : webShopMenuPosition != 'horizontal'  && shopType != 'kiosk' && (
            
            <LanguageMenuItem
            languageData={languageData}
          updateLanguageData={setLanguageData}
          defaultLanguage={defaultLanguage}
          updateDefaultLanguage={updateDefaultLanguage}
             />
          )}
        </Box>
        <Box >
          {isSmallScreen ? (
            <LanguageMenuItem
            languageData={languageData}
          updateLanguageData={setLanguageData}
          defaultLanguage={defaultLanguage}
          updateDefaultLanguage={updateDefaultLanguage}
             />
          ) : shopType == 'kiosk'  ?  <LanguageMenuItem
          languageData={languageData}
          updateLanguageData={setLanguageData}
          defaultLanguage={defaultLanguage}
          updateDefaultLanguage={updateDefaultLanguage}
           /> :  (
            <TextField
              variant="outlined"
              name={'searchItem'}
              value={searchItem}
              placeholder="Search here..."
              onChange={handleSearchChange}
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              sx={{
                backgroundColor: "#fff",
                borderRadius: 2,
                "& .MuiOutlinedInput-root": {
                  borderRadius: 4,
                  border: "none",
                  "& fieldset": {
                    border: "lightgrey", // Remove border
                  },
                  "&:hover fieldset": {
                    border: "lightgrey", // Prevent border on hover
                  },
                  "&.Mui-focused fieldset": {
                    border: "lightgrey", // Prevent border when focused
                  },
                },
              }}
            />
          )}
        </Box>
      </Box>
      <Box sx={{mt:2}}>
     {!isSmallScreen  && <Typography
              sx={{ fontSize: "1.5rem", fontWeight: "600", color: "#00000" }}
            >
            {selectedMenuItem?.name}
            </Typography>}
            <Typography
              sx={{ fontSize: isSmallScreen ? "10px" : "16px",maxWidth:"970px", color: "#969BA0"}}
            >
            {selectedMenuItem?.info}
            </Typography>

      </Box>
      <Box>
        <MenuListCardsComponent data={tempList}/>
      </Box>
      <Grid2 container mt={4} sx={{width:"100%",pb:2}}> 
      {!webShopMenuPosition && <Grid2 size={{md:1}}>
        <Box
              sx={{
             //   flex: "0 1 auto",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <img
                src={footerImg}
                alt="footerLogo"
                style={{ minWidth: "76px", height: "auto" }}
              />
            </Box>
        </Grid2>}
        <Grid2 size={{md:8}}>
            <Typography sx={{color:"#969BA0",textWrap:"wrap"}}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed vel ipsum eget nisi placerat viverra. Donec non ipsum vel nisi fermentum consectetur. Nulla facilisi. Donec id velit vel eros dictum tempor.
            </Typography>
        </Grid2>
        <Grid2 size={{md:3}}>
            <Box sx={{display:'flex',justifyContent:"flex-end"}}>
            <Button onClick={()=>{
              if(addToCartProducts?.length > 0){
                dispatch(setOpenCart(true))
              }
            }} size='large' sx={{backgroundColor:"#F37120",color:"#fff"}} variant="contained" endIcon={
              <Badge
              badgeContent={totalProductsCount}
              color="primary"
              sx={{
                ".MuiBadge-dot": {
                  backgroundColor: "black ",  // Set background color to black for the badge
                },
                ".MuiBadge-dotColorPrimary": {
                  backgroundColor: "black",  // Set the background color to black for primary badge
                },
                ".MuiBadge-dot .MuiBadge-dot": {
                  color: "white",            // Set the text color inside the badge to white
                }
              }}
            >
              <ShoppingCartIcon sx={{ color: "#fff" }} />
            </Badge>
           }
            >
  View Cart
</Button>

            </Box>

        </Grid2>


    </Grid2>

    </Box>
  );
};

export default MenuItemListComponent;
